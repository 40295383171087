import { Type, plainToClass } from 'class-transformer';
import 'reflect-metadata';

import './types';
import {
    GPSPoint as GPSPointProto, GPSPointArray,
    Point2D as Point2DProto, GpsPointMapping, ResortSnow,
} from './proto/common';
import {
    AllResorts2, AllResorts2_Resort,
    GetResortsResponse2,
    AllResortBoundaries, AllResortBoundaries_Resort,
    AllResortBoundaries_Geometry,
} from './proto/requests';
import {
    Resort as ResortProto,
    Resort_Lift, Resort_SkiRun, Resort_Restaurant, Resort_ExtraPoint,
    Resort_Map, Resort_HoursOfOperation, SkiRunDifficulty,
} from './proto/common';

import RBush from 'rbush';
import {
    point, polygon,
} from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

export type LngLat = [number, number];

export class Account {
    // Properties common to all accounts. See datamodel.Account
    type?: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
    image?: string;
    largeImage?: string;

    // True if this account was used to login.
    current: boolean = false;

    // Properties common to specific type of accounts.
    email?: string;
    username?: string;
    screenName?: string;
}

export class User {
    identifier?: string;

    firstName?: string;

    lastName?: string;

    displayName?: string;

    image?: string;

    largeImage?: string;

    lang?: string;

    entitlements: any = {};

    distanceUnits: string = 'metric';
    elevationUnits: string = 'metric';
    temperatureUnits: string = 'celsius';

    @Type(() => Account)
    accounts: Account[] = [];

    // Sorts the accounts to be listed in preferred order: Google,
    // Facebook, Twitter, Apple, Microsoft
    sortAccounts(): void {
        this.accounts = ([] as Account[]).concat(
            this.accounts.filter((account) => account.type == 'apple'),
            this.accounts.filter((account) => account.type == 'google'),
            this.accounts.filter((account) => account.type == 'facebook'),
            this.accounts.filter((account) => account.type == 'twitter'),
            this.accounts.filter((account) => account.type == 'microsoft')
        );
    }

    getCurrentAccount(): Account | undefined {
        return this.accounts.find((account) => account.current);
    }

    getAccountsExceptCurrent(): Account[] {
        return this.accounts.filter((account) => !account.current);
    }

    userSmallImage() {
        if (this.image) {
            return this.image;
        } else {
            // A 32x32 image
            return '/assets/jpg/unknown-skier-small.png';
        }
    }

    userLargeImage() {
        if (this.largeImage) {
            return this.largeImage;
        } else {
            return '/assets/jpg/unknown-skier-large.png';
        }
    }
}


class Geometry {
    public object: Continent | Country | ResortBrief;
    public boundary: GPSPoint[] = [];

    public minX: number = NaN;
    public minY: number = NaN;
    public maxX: number = NaN;
    public maxY: number = NaN;

    public constructor(
        object: Continent | Country | ResortBrief,
        bb: { proto?: GPSPointArray, points?: GPSPoint[] }) {
        this.object = object;
        if (bb.proto) {
            this.boundary = GPSPoint.gpsPointsFromArray(bb.proto);
            if (this.boundary.length > 1) {
                let first = this.boundary[0];
                this.boundary.push(first);
            }
        } else if (bb.points) {
            this.boundary = bb.points;
        } else {
            console.error("Bad Geometry invocation");
            return;
        }

        // let poly = polygon([GPSPoint.toMapbox(this.boundary)]);
        // let p = point([-119.911785, 38.941334]);
        // if (booleanPointInPolygon(p, poly)) {
        //     console.log("World found matching polygon in", object);
        //     console.log("Matching polygon", poly)
        // }

        if (this.boundary) {
            let bounds = GPSPoint.computeBounds(this.boundary);
            let sw = bounds[0];
            let ne = bounds[1];
            this.minX = sw.lng;
            this.minY = sw.lat;
            this.maxX = ne.lng;
            this.maxY = ne.lat;
        }
    }

    public geojsonFeature(): any {
        return {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: this.boundary.map((p: GPSPoint) => p.mapbox()),
            },
        };
    }
}

export class World {
    public name: string = "Ski Resorts";
    private continents: Continent[] = [];
    private continentsByName: { [name: string]: Continent } = {};
    private continentsByUrlizedName: { [name: string]: Continent } = {};

    private countries: Country[] = [];
    private countriesByName: { [name: string]: Country } = {};
    private countriesByUrlizedName: { [name: string]: Country } = {};

    resorts: ResortBrief[] = [];
    private resortsByUid: { [uid: number]: ResortBrief } = {};
    private resortsByName: { [name: string]: ResortBrief } = {};

    // private kdtree: RBush<ResortBrief> = new RBush();

    // An array of 2 GPSPoint points: the southwest and northeast
    // corners of a rectangle containing all the points in the world.
    private _bounds: [GPSPoint, GPSPoint] = [
        new GPSPoint().with(90, 180, 0),
        new GPSPoint().with(-90, -180, 0)
    ];
    get bounds(): [GPSPoint, GPSPoint] {
        return this._bounds;
    }

    private _numSkiDays: number = 0;
    public get numSkiDays() { return this._numSkiDays; }
    public set numSkiDays(value: number) {
        this._numSkiDays = value;
    }

    // private _continentCountryGeoJSON: any = undefined;
    // public continentCountryGeoJSON() {
    //     if (!this._continentCountryGeoJSON) {
    //         this.createRBushTree();
    //     }
    //     return this._continentCountryGeoJSON;
    // }

    private geometriesTree: RBush<Geometry> | undefined;
    private countriesWithGeom: Country[] = [];

    constructor(allResorts: AllResorts2) {
        let start = performance.now();
        for (let resortProto of allResorts.resorts) {
            // console.log("resort", resortProto);
            let continent: Continent = this.getOrCreateContinent(resortProto.continent);
            let country: Country = continent.getOrCreateCountry(resortProto.countries[0]);
            // let resort: ResortBrief;
            if (resortProto.states.length) {
                let state: State = country.getOrCreateState(resortProto.states[0]);
                // resort =
                new ResortBrief(resortProto, country, state);
            } else {
                // resort =
                new ResortBrief(resortProto, country);
            }
        }
        this.sortContinents();

        console.log("World", this);
        let end = performance.now();
        let duration = end - start;
        console.log(`Processed AllResorts2 in ${duration} milliseconds `);

        // start = performance.now();
        // this.kdtree.load(this.resorts);
        // end = performance.now();
        // duration = end - start;
        // console.log(`KDTree created from AllResorts2 in ${duration} milliseconds`, this.resorts);
    }

    public processBoundaries(proto: AllResortBoundaries) {
        console.log("Datamodel processBoundaries", proto);
        let start = performance.now();
        for (let continentGeomProto of proto.continents) {
            let continent = this.continentsByName[continentGeomProto.name];
            if (!continent) {
                console.error("Could not find continent", continentGeomProto.name);
                continue;
            }
            continent.geomProto = continentGeomProto;
        }

        for (let countryGeomProto of proto.countries) {
            let country = this.countriesByName[countryGeomProto.name];
            if (!country) {
                console.error("Could not find country", countryGeomProto.name);
                continue;
            }
            country.geomProto = countryGeomProto;
            this.countriesWithGeom.push(country);
        }

        for (let r of proto.resorts) {
            let resort: ResortBrief = this.getResortWithUid(r.resortUid);
            if (!resort) {
                console.log("Datamodel couldn't find resort uid", r.resortUid);
                continue;
            }

            resort.difficulties = r.difficulties.sort();
            if (r.boundary) {
                let points: GPSPoint[] = GPSPoint.gpsPointsFromArray(r.boundary);
                points.push(points[0]);

                // console.log("Datamodel boundary GPSPoints for resort",
                //     resort.name, ":", points);
                let turfpoints = points.map((p: GPSPoint) => [p.lng, p.lat]);
                resort.boundary = polygon([turfpoints], { name: 'boundary' });
                // console.log("Datamodel resort", resort.name,
                //     "boundary", resort.boundary);
            }
        }

        let end = performance.now();
        let duration = end - start;
        console.log(`Processed AllResortBoundaries in ${duration} milliseconds `);
    }

    getOrCreateContinent(name: string): Continent {
        let continent = this.continentsByName[name];

        if (continent) {
            return continent;
        }
        continent = new Continent(name, this);

        this.continentsByName[name] = continent;
        this.continentsByUrlizedName[name.urlize()] = continent;
        return continent;
    }

    addCountry(country: Country) {
        this.countries.push(country);
        this.countriesByName[country.name] = country;
        this.countriesByUrlizedName[country.name.urlize()] = country;
    }

    addResort(resort: ResortBrief) {
        this.resorts.push(resort);
        this.resortsByUid[resort.resortUid] = resort;
        this.resortsByName[resort.name] = resort;
        this._bounds = GPSPoint.extendBounds(this._bounds, resort.centroid!);
    }

    sortContinents() {
        let na = this.continentsByName['North America'];
        let europe = this.continentsByName['Europe'];
        let asia = this.continentsByName['Asia'];
        let sa = this.continentsByName['South America'];
        let australia = this.continentsByName['Australia'];
        let nz = this.continentsByName['Zealandia'];
        let africa = this.continentsByName['Africa'];
        this.continents = [na, europe, asia, sa, australia, nz, africa];
        // console.log("Continents", this.continents);

        for (let continent of this.continents) {
            continent.sortCountries();
        }
    }

    getContinentByUrlizedName(name: string): Continent {
        return this.continentsByUrlizedName[name];
    }

    getCountryByName(name: string): Country {
        return this.countriesByName[name];
    }

    getContinents(): Continent[] {
        return this.continents;
    }

    getCountryByUrlizedName(name: string): Country {
        return this.countriesByUrlizedName[name];
    }

    getResortWithUid(uid: number): ResortBrief {
        return this.resortsByUid[uid];
    }

    getResortWithName(name: string): ResortBrief {
        return this.resortsByName[name];
    }

    getResorts(): ResortBrief[] {
        return this.resorts;
    }

    getSchemaItemType() { return ''; }

    // getResortsIntersectingBounds(sw: LngLat, ne: LngLat): ResortBrief[] {
    //     // console.log("Search over tree", this.kdtree, "sw", sw, "ne", ne);
    //     return this.kdtree.search({
    //         minX: sw[0],
    //         minY: sw[1],
    //         maxX: ne[0],
    //         maxY: ne[1]
    //     });
    // }

    private createRBushTree() {
        let start = performance.now();

        // let features: any = [];

        let geometries: Geometry[] = [];
        for (let continent of this.continents) {
            if (continent.geomProto) {
                for (let pointsProto of continent.geomProto.polygon) {
                    let geometry = new Geometry(continent, { proto: pointsProto });
                    geometries.push(geometry);
                    // if (geometry.boundary.length > 500) {
                    // features.push(geometry.geojsonFeature());
                    // console.log("World added feature for geometry",
                    //     geometry);
                    // }
                    // console.log("Continent", continent.name,
                    //     "geometry", geometry);
                }
            }
        }

        for (let country of this.countriesWithGeom) {
            if (country.geomProto) {
                for (let pointsProto of country.geomProto.polygon) {
                    let geometry = new Geometry(country, { proto: pointsProto });
                    geometries.push(geometry);
                    // if (geometry.boundary.length > 500) {
                    // features.push(geometry.geojsonFeature());
                    // }
                }
            }
        }

        for (let r of this.resorts) {
            if (r.northeast && r.southwest) {
                let geometry = new Geometry(r, { points: [r.northeast, r.southwest] });
                geometries.push(geometry);
                // features.push(geometry.geojsonFeature());
            }
        }

        // console.log("World Got %s geometries", geometries.length, geometries);

        this.geometriesTree = new RBush(16);
        this.geometriesTree.load(geometries);
        // this._continentCountryGeoJSON = {
        //     type: 'geojson',
        //     data: {
        //         type: 'FeatureCollection',
        //         features: features,
        //     },
        // };

        let end = performance.now();
        let duration = end - start;
        console.log(`World RBush created from AllResorts2 in ${duration} milliseconds`, this.geometriesTree);
    }

    public matchingWorldObjectsForPoint(lat: number, lng: number):
        Continent | Country | ResortBrief | undefined {
        // console.log("World Searching for objects at", lat, lng);
        if (!this.geometriesTree) {
            this.createRBushTree();
            // console.log("World Created RBush tree", this.geometriesTree);
        }
        if (!this.geometriesTree) {
            // console.error("World No geometriesTree was created");
            return undefined;
        }
        let matches = this.geometriesTree.search({
            minX: lng - 0.01,
            minY: lat - 0.01,
            maxX: lng + 0.01,
            maxY: lat + 0.01,
        });

        let indices = [ResortBrief, State, Country, Continent];
        let cmpFn = (obj: any) => {
            for (let i = 0; i < indices.length; i++) {
                let type = indices[i];
                if (obj instanceof type) {
                    return i;
                }
            }
            return -1;
        };

        if (matches.length > 0) {
            matches.sort((a: Geometry, b: Geometry) => {
                let aIdx = cmpFn(a.object);
                let bIdx = cmpFn(b.object);

                return aIdx - bIdx;
                // if (a.object instanceof ResortBrief) {
                //     return -1;
                // }
                // if (a.object instanceof Country) {
                //     return -1;

                // }
                // return 0;
            });

            let turfPoint = point([lng, lat]);
            let match = matches[0].object;
            for (let geom of matches) {
                let obj = geom.object;
                if (obj instanceof ResortBrief) {
                    let boundaryPolygon = (obj as ResortBrief).boundary;
                    if (booleanPointInPolygon(turfPoint, boundaryPolygon)) {
                        match = obj;
                        console.log("Datamodel found location inside", obj);
                        break;
                    }
                }
            }

            console.log("World RBush found matches", matches);
            // return matches[0].object;
            return match;
        }
        // console.log("World RBush found NO matches");
        return undefined;
    }
}

export class GPSPoint {
    public lat: number = 0;
    public lng: number = 0;
    public alt: number = 0;

    get latE6() { return this.lat * 1e6; }
    get lngE6() { return this.lng * 1e6; }

    with(lat: number, lng: number, alt: number): GPSPoint {
        this.lat = lat;
        this.lng = lng;
        this.alt = alt;
        return this;
    }

    from(proto: GPSPointProto): GPSPoint {
        this.lat = proto.latE6 / 1e6;
        this.lng = proto.lngE6 / 1e6;
        this.alt = proto.altE2 / 1e2;
        return this;
    }

    toProto(): GPSPointProto {
        let proto = GPSPointProto.create();
        proto.latE6 = this.lat * 1e6;
        proto.lngE6 = this.lng * 1e6;
        proto.altE2 = this.alt * 1e2;
        return proto;
    }

    mapbox(): LngLat {
        return [this.lng, this.lat];
    }

    str() {
        return `${this.lat}, ${this.lng}`;
    }

    almostEqual(gpspoint: GPSPointProto): boolean {
        const epsilon = 2;

        return Math.abs(this.latE6 - gpspoint.latE6) < epsilon &&
            Math.abs(this.lngE6 - gpspoint.lngE6) < epsilon;
    }

    equals(other: GPSPoint): boolean {
        let epsilon = 1e-6;

        return Math.abs(this.lat - other.lat) < epsilon &&
            Math.abs(this.lng - other.lng) < epsilon;
    }

    isValid(): boolean {
        return !(isNaN(this.lat) || isNaN(this.lng) || isNaN(this.alt));
    }

    static toMapbox(gpsPoints: GPSPoint[]): LngLat[] {
        return gpsPoints.map((p) => p.mapbox());
    }

    // Extends the bounds by potentially adding `p` to `bounds`.
    static extendBounds(bounds: [GPSPoint, GPSPoint], p: GPSPoint): [GPSPoint, GPSPoint] {
        let sw = bounds[0];
        let ne = bounds[1];
        if (p.lat < sw.lat) {
            sw.lat = p.lat;
        }
        if (p.lng < sw.lng) {
            sw.lng = p.lng;
        }
        if (p.lat > ne.lat) {
            ne.lat = p.lat;
        }
        if (p.lng > ne.lng) {
            ne.lng = p.lng;
        }
        return [sw, ne];
    }

    static computeBounds(gpspoints: GPSPoint[]) {
        let bounds: [GPSPoint, GPSPoint] = [
            new GPSPoint().with(90, 180, 0),
            new GPSPoint().with(-90, -180, 0)
        ];

        for (let p of gpspoints) {
            if (Math.abs(p.lng + 180) < 1) {
                console.log("World geometry point close to -180", p);
            }
            bounds = GPSPoint.extendBounds(bounds, p);
        }
        return bounds;
    }

    static gpsPointsFromArray(proto: GPSPointArray): GPSPoint[] {
        let latitudes = proto.latitudes;
        let longitudes = proto.longitudes;
        let altitudes = proto.altitudes;

        let prev: GPSPoint = new GPSPoint().with(
            latitudes[0] / 1e6,
            longitudes[0] / 1e6,
            altitudes[0] / 1e2);

        let points: GPSPoint[] = [prev];

        for (let i = 1; i < latitudes.length; i++) {
            let lat = latitudes[i] / 1e6;
            let lng = longitudes[i] / 1e6;
            let alt = altitudes[i] / 1e2;
            let p = new GPSPoint().with(
                prev.lat + lat,
                prev.lng + lng,
                prev.alt + alt);
            points.push(p);
            prev = p;
        }
        // for (let p of points) {
        //     if (p.lat < 0) {
        //         console.log("Found point in the southern hemisphere", p);
        //     }
        // }
        return points;
    }
}

export class Location {
    gpspoint: GPSPoint;

    date: Date;
    horizontalAccuracy: number;
    verticalAccuracy: number;
    speed: number;
    direction: number;

    constructor(lat: number, lng: number, alt: number, date: Date,
        horizontalAccuracy: number, verticalAccuracy: number,
        speed: number, direction: number) {
        this.gpspoint = new GPSPoint().with(lat, lng, alt);
        this.date = date;
        this.horizontalAccuracy = horizontalAccuracy;
        this.verticalAccuracy = verticalAccuracy;
        this.speed = speed;
        this.direction = direction;
    }
}

export class Continent {
    public name: string;
    public world: World;

    private countries: Country[] = [];
    private countriesByName: { [name: string]: Country } = {};
    private countriesByUrlizedName: { [name: string]: Country } = {};

    public resorts: ResortBrief[] = [];

    public geomProto: AllResortBoundaries_Geometry | undefined;

    // An array of 2 GPSPoint points: the southwest and northeast
    // corners of a rectangle containing all the points on a continent.
    private _bounds: [GPSPoint, GPSPoint] = [
        new GPSPoint().with(90, 180, 0),
        new GPSPoint().with(-90, -180, 0)
    ];
    get bounds(): [GPSPoint, GPSPoint] {
        return this._bounds;
    }

    private _numSkiDays: number = 0;
    public get numSkiDays() { return this._numSkiDays; }
    public set numSkiDays(value: number) {
        this.world.numSkiDays -= this._numSkiDays;
        this._numSkiDays = value;
        this.world.numSkiDays += value;
    }

    constructor(name: string, world: World) {
        this.name = name;
        this.world = world;
    }

    sortCountries() {
        this.countries.sort((a, b) => (a.name > b.name ? 1 : -1));

        for (let country of this.countries) {
            country.sortStates();
            country.sortResorts();
        }
    }

    addResort(resort: ResortBrief) {
        this.resorts.push(resort);

        // Improvement suggested by paul@laszlo.ltd on Nov 19, 2024,
        // to ignore Russia when computing the bounds for Europe.
        if (resort.country.continent.name == 'Europe') {
            if (resort.country.name != 'Russia') {
                this._bounds = GPSPoint.extendBounds(this._bounds, resort.centroid!);
            }
        } else {
            this._bounds = GPSPoint.extendBounds(this._bounds, resort.centroid!);
        }

        this.world.addResort(resort);
    }

    getSchemaItemType() { return 'http://schema.org/Continent'; }

    getOrCreateCountry(name: string): Country {
        let country = this.countriesByName[name];
        if (!country) {
            country = new Country(name, this);
            country.continent = this;
            this.world.addCountry(country);

            this.countries.push(country);
            this.countriesByName[name] = country;
            this.countriesByUrlizedName[name.urlize()] = country;
        }
        return country;
    }

    getCountryByUrlizedName(name: string): Country {
        return this.countriesByUrlizedName[name];
    }

    getCountries(): Country[] {
        return this.countries;
    }

    getResorts(): ResortBrief[] {
        return this.resorts;
    }
}

export class Country {
    public name: string;
    public continent: Continent;

    private states: State[] = [];
    private statesByName: { [name: string]: State } = {};
    private statesByUrlizedName: { [name: string]: State } = {};

    public resorts: ResortBrief[] = [];
    private resortsByName: { [name: string]: ResortBrief } = {};
    private resortsByUrlizedName: { [name: string]: ResortBrief } = {};
    private resortsByUrlizedPreviousName: { [name: string]: ResortBrief } = {};

    // An array of 2 GPSPoint points: the southwest and northeast
    // corners of a rectangle containing all the points in a country.
    private _bounds: [GPSPoint, GPSPoint] = [
        new GPSPoint().with(90, 180, 0),
        new GPSPoint().with(-90, -180, 0)
    ];
    get bounds(): [GPSPoint, GPSPoint] {
        return this._bounds;
    }

    private _numSkiDays: number = 0;
    public get numSkiDays() { return this._numSkiDays; }
    public set numSkiDays(value: number) {
        this.continent.numSkiDays -= this._numSkiDays;
        this._numSkiDays = value;
        this.continent.numSkiDays += value;
    }

    public geomProto: AllResortBoundaries_Geometry | undefined;

    constructor(name: string, continent: Continent) {
        this.name = name;
        this.continent = continent;
    }

    sortStates() {
        this.states.sort((a, b) => (a.name > b.name ? 1 : -1));
        for (let state of this.states) {
            state.sortResorts();
        }
    }

    sortResorts() {
        this.resorts.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    getSchemaItemType() { return 'http://schema.org/Country'; }

    getOrCreateState(name: string): State {
        let state = this.statesByName[name];
        if (!state) {
            state = new State(name, this);
            state.country = this;

            this.states.push(state);
            this.statesByName[name] = state;
            this.statesByUrlizedName[name.urlize()] = state;
        }
        return state;
    }

    addResort(resort: ResortBrief) {
        this.resorts.push(resort);
        this.resortsByName[resort.name] = resort;
        this.resortsByUrlizedName[resort.name.urlize()] = resort;
        if (resort.previousNames) {
            for (let name of resort.previousNames) {
                this.resortsByUrlizedPreviousName[name.urlize()] = resort;
            }
        }
        this._bounds = GPSPoint.extendBounds(this._bounds, resort.centroid!);

        this.continent.addResort(resort);
    }

    getStateByName(name: string): State {
        return this.statesByName[name];
    }

    getStateByUrlizedName(name: string): State {
        return this.statesByUrlizedName[name];
    }

    getResortByUrlizedName(name: string): ResortBrief {
        return this.resortsByUrlizedName[name];
    }

    getResortByUrlizedPreviousName(name: string): ResortBrief {
        return this.resortsByUrlizedPreviousName[name];
    }

    getStates(): State[] {
        return this.states;
    }

    getResorts(): ResortBrief[] {
        return this.resorts;
    }
}

export class State {
    public name: string;
    public country: Country;

    public resorts: ResortBrief[] = [];
    private resortsByName: { [name: string]: ResortBrief } = {};
    private resortsByUrlizedName: { [name: string]: ResortBrief } = {};
    private resortsByUrlizedPreviousName: { [name: string]: ResortBrief } = {};

    // An array of 2 GPSPoint points: the southwest and northeast
    // corners of a rectangle containing all the points in a state.
    private _bounds: [GPSPoint, GPSPoint] = [
        new GPSPoint().with(90, 180, 0),
        new GPSPoint().with(-90, -180, 0)
    ];
    get bounds(): [GPSPoint, GPSPoint] {
        return this._bounds;
    }

    private _numSkiDays: number = 0;
    public get numSkiDays() { return this._numSkiDays; }
    public set numSkiDays(value: number) {
        let oldValue = this._numSkiDays;
        this._numSkiDays = value;
        if (this.country) {
            this.country.numSkiDays -= oldValue;
            this.country.numSkiDays += value;
        }
    }

    constructor(name: string, country: Country) {
        this.name = name;
        this.country = country;
    }

    sortResorts() {
        this.resorts.sort((a, b) => (a.name > b.name ? 1 : -1));
    }

    getSchemaItemType() { return 'http://schema.org/State'; }

    addResort(resort: ResortBrief) {
        this.resorts.push(resort);
        this.resortsByName[resort.name] = resort;
        this.resortsByUrlizedName[resort.name.urlize()] = resort;
        if (resort.previousNames) {
            for (let name of resort.previousNames) {
                this.resortsByUrlizedPreviousName[name.urlize()] = resort;
            }
        }
        this._bounds = GPSPoint.extendBounds(this._bounds, resort.centroid!);
    }

    getResorts(): ResortBrief[] {
        return this.resorts;
    }
}

export class ResortBrief {
    public resortUid: number;
    public name: string;
    public nameJa?: string;
    public nameCyrillic?: string;

    public country: Country;
    public state?: State;

    public centroid?: GPSPoint;
    public radius?: number;
    public northeast?: GPSPoint;
    public southwest?: GPSPoint;
    public timezone?: string;

    public numberOfLifts?: number = 0;
    public baseElevation?: number = 0;
    public topElevation?: number = 0;
    public mostVerticalForALift?: number = 0;
    public numberOfSkiRuns?: number = 0;
    public totalLengthOfSkiRuns?: number = 0;
    public lengthOfLongestSkiRun?: number = 0;
    public mostVerticalForASkiRun?: number = 0;

    public seasonIsOpen?: boolean = false;
    public seasonStart?: Date;
    public seasonEnd?: Date;

    // Values here are in cm
    public snowLast24HrsMin?: number;
    public snowLast24HrsMax?: number;
    public baseDepthMin?: number;
    public baseDepthMax?: number;

    private snow?: ResortSnow;
    // public maps: AllResorts2_Map[];

    public previousNames?: string[];
    // This field is used by flexsearch for search
    public concatenatedPreviousNames?: string;

    public difficulties: SkiRunDifficulty[] = [];

    private _numSkiDays: number = 0;
    public get numSkiDays() { return this._numSkiDays; }
    public set numSkiDays(value: number) {
        let oldValue = this._numSkiDays;
        this._numSkiDays = value;
        if (this.state) {
            this.state.numSkiDays -= oldValue;
            this.state.numSkiDays += value;
        } else if (this.country) {
            this.country.numSkiDays -= oldValue;
            this.country.numSkiDays += value;
        }
    }

    public manualSnow?: boolean;

    get bounds(): [GPSPoint, GPSPoint] {
        return [this.southwest!, this.northeast!];
    }

    public boundary: any;

    constructor(proto: AllResorts2_Resort, country: Country, state?: State) {
        this.resortUid = proto.resortUid;

        this.name = proto.name;
        this.nameJa = proto.nameJa;
        this.nameCyrillic = proto.nameCyrillic;

        if (proto.centroid) {
            this.centroid = new GPSPoint().from(proto.centroid);
        }
        if (proto.radiusE2) {
            this.radius = proto.radiusE2 / 1e2;
        }
        if (proto.northeast) {
            this.northeast = new GPSPoint().from(proto.northeast);
        }
        if (proto.southwest) {
            this.southwest = new GPSPoint().from(proto.southwest);
        }
        this.timezone = proto.timezone;

        this.numberOfLifts = proto.numberOfLifts;
        if (proto.baseElevationE2) {
            this.baseElevation = proto.baseElevationE2 / 100;
        }
        if (proto.topElevationE2) {
            this.topElevation = proto.topElevationE2 / 100;
        }
        if (proto.mostVerticalForALiftE2) {
            this.mostVerticalForALift = proto.mostVerticalForALiftE2 / 100;
        }
        this.numberOfSkiRuns = proto.numberOfSkiruns;
        if (proto.totalLengthOfSkirunsE2) {
            this.totalLengthOfSkiRuns = proto.totalLengthOfSkirunsE2 / 100;
        }
        if (proto.lengthOfLongestSkirunE2) {
            this.lengthOfLongestSkiRun = proto.lengthOfLongestSkirunE2 / 100;
        }
        if (proto.mostVerticalForASkirunE2) {
            this.mostVerticalForASkiRun = proto.mostVerticalForASkirunE2 / 100;
        }

        this.seasonIsOpen = proto.seasonIsOpen;
        this.seasonStart = this.parseDate(proto.seasonStart);
        this.seasonEnd = this.parseDate(proto.seasonEnd);

        let snow: ResortSnow | undefined = proto.snow;
        if (snow) {
            // console.log("Got snow for resort", this.name, ":", snow);
            if (snow.snowfallLast24HrMin !== undefined) {
                this.snowLast24HrsMin = snow.snowfallLast24HrMin;
            }
            if (snow.snowfallLast24HrMax !== undefined) {
                this.snowLast24HrsMax = snow.snowfallLast24HrMax;
            }

            [this.snowLast24HrsMin, this.snowLast24HrsMax] =
                this.fixMinMaxValues(this.snowLast24HrsMin, this.snowLast24HrsMax);

            if (snow.baseDepthMin !== undefined) {
                this.baseDepthMin = snow.baseDepthMin;
            }
            if (snow.baseDepthMax !== undefined) {
                this.baseDepthMax = snow.baseDepthMax;
            }
            [this.baseDepthMin, this.baseDepthMax] =
                this.fixMinMaxValues(this.baseDepthMin, this.baseDepthMax);

            // console.log("Resort after snow added", this);
        }

        // this.maps = proto.maps;

        let name_urlized = this.name.urlize();
        if (proto.previousNames && proto.previousNames.length > 0) {
            this.previousNames = [];
            this.concatenatedPreviousNames = "";
            for (let name of proto.previousNames) {
                if (name_urlized != name.urlize()) {
                    this.previousNames.push(name);
                    this.concatenatedPreviousNames += name + ", ";
                }
            }
        }

        this.country = country;
        this.state = state;

        // Format is YYYY-MM-DD

        this.country.addResort(this);
        if (this.state) {
            this.state.addResort(this);
        }
    }

    private fixMinMaxValues(min?: number, max?: number) {
        if (min === undefined && max !== undefined) {
            min = max;
        } else if (min !== undefined && max === undefined) {
            max = min;
        }
        return [min, max];
    }

    getSchemaItemType() { return 'http://schema.org/SkiResort'; }

    private parseDate(str?: string): Date | undefined {
        if (str) {
            let dateParts = str.split('-');
            if (dateParts && dateParts.length == 3) {
                let date = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
                return date;
            }
        }
        return undefined;
    }

    // // RBush properties
    // public get minX() { return this.southwest!.lng; }
    // public get maxX() { return this.northeast!.lng; }
    // public get minY() { return this.southwest!.lat; }
    // public get maxY() { return this.northeast!.lat; }
}

export class Resort {
    public world: World;
    public country: Country;
    public state: State;
    public resortUid: number;

    public name: string;
    public nameJa?: string;
    public nameCyrillic?: string;
    public centroid?: GPSPoint;

    public radius: number;
    public timezone?: string;
    public url?: string;

    public hasHeatmap: boolean = false;

    public lifts: Lift[] = [];
    private liftsById: { [id: number]: Lift } = {};
    private liftsByName: { [name: string]: Lift } = {};

    public skiruns: SkiRun[] = [];
    private skirunsById: { [id: number]: SkiRun } = {};
    private skirunsByName: { [name: string]: SkiRun } = {};

    public restaurants: Restaurant[] = [];
    private restaurantsByName: { [name: string]: Restaurant } = {};

    public boundary: GPSPoint[] = [];

    public maps: Map[] = [];
    private mapsByUrlizedName: { [name: string]: Map } = {};

    private skirunDifficulties: SkiRunDifficulty[] = [];
    private skirunsByDifficulty: any = {};

    // An array of 2 GPSPoint points: the southwest and northeast
    // corners of a rectangle containing all the points in a resort.
    private _bounds: [GPSPoint, GPSPoint] = [
        new GPSPoint().with(90, 180, 0),
        new GPSPoint().with(-90, -180, 0)
    ];
    get bounds(): [GPSPoint, GPSPoint] {
        return this._bounds;
    }

    public center: GPSPoint;

    // Computed values
    baseElevation: number = 100000;
    topElevation: number = -100000;
    verticalDrop: number = 0;

    liftWithMostVertical?: Lift;
    liftWithHighestElevation?: Lift;

    longestSkiRun?: SkiRun;
    mostVerticalSkiRun?: SkiRun;

    // Snow
    snowFall24HrsMin?: number;
    snowFall24HrsMax?: number;
    baseDepthMin?: number;
    baseDepthMax?: number;

    // Weather
    iconCSSname: string = 'weather_unknown';
    temperature?: number;
    windKph?: number;
    windDirection?: string;
    windGustKph?: number;
    windChillTemperature?: number;
    visibilityKm?: number;

    constructor(proto: ResortProto, world: World) {
        this.world = world;
        this.country = world.getCountryByName(proto.country[0]);
        this.state = this.country.getStateByName(proto.state[0]);

        this.resortUid = proto.resortUid;
        this.name = proto.name;
        this.nameJa = proto.nameJa;
        this.nameCyrillic = proto.nameCyrillic;
        if (proto.centroid) {
            this.centroid = new GPSPoint().from(proto.centroid);
        }

        console.log("Resort proto", proto);

        this.radius = proto.radiusE2 / 1e2;
        this.timezone = proto.timezone;
        this.url = proto.url;

        for (let protoLift of proto.lifts) {
            let lift: Lift = new Lift(protoLift, this);
            this.lifts.push(lift);
            this.liftsById[lift.liftUid] = lift;
            this.liftsByName[lift.name.toLowerCase()] = lift;
            this.liftsByName[lift.name.urlize()] = lift;
            this.liftsByName[encodeURIComponent(lift.name).toLowerCase()] = lift;

            for (let p of lift.points) {
                this.extendBounds(p);
            }
        }
        this.lifts.sort((a, b) => a.name < b.name ? -1 : 1);

        for (let protoSkiRun of proto.skiruns) {
            let skirun: SkiRun = new SkiRun(protoSkiRun, this);
            this.skiruns.push(skirun);
            this.skirunsById[skirun.skirunUid] = skirun;
            this.skirunsByName[skirun.name.urlize()] = skirun;
            this.skirunsByName[encodeURIComponent(skirun.name).toLowerCase()] = skirun;
            this.skirunsByName[skirun.name.toLowerCase()] = skirun;

            for (let p of skirun.points) {
                this.extendBounds(p);
            }
            let difficulty = skirun.difficulty;
            if (!this.skirunDifficulties.includes(difficulty)) {
                this.skirunDifficulties.push(difficulty);
                this.skirunsByDifficulty[difficulty] = [];
            }
            this.skirunsByDifficulty[difficulty].push(skirun);
        }

        this.skirunDifficulties.sort((a, b) => a > b ? 1 : -1);

        for (let difficulty of this.skirunDifficulties) {
            let runs: SkiRun[] = this.skirunsByDifficulty[difficulty];
            runs.sort((a, b) => a.name < b.name ? -1 : 1);
            this.skirunsByDifficulty[difficulty] = runs;
        }

        for (let protoRestaurant of proto.restaurants) {
            let restaurant: Restaurant = new Restaurant(protoRestaurant, this);
            this.restaurants.push(restaurant);
            this.restaurantsByName[restaurant.name.toLowerCase()] = restaurant;
            this.restaurantsByName[restaurant.name.urlize()] = restaurant;
            this.restaurantsByName[encodeURIComponent(restaurant.name).toLowerCase()] = restaurant;

            if (restaurant.location) {
                this.extendBounds(restaurant.location);
            }
        }
        this.restaurants.sort((a, b) => a.name < b.name ? -1 : 1);

        if (proto.boundary) {
            this.boundary = GPSPoint.gpsPointsFromArray(proto.boundary);
        }

        for (let protoMap of proto.maps) {
            let map: Map = new Map(protoMap);
            if (!map.label) {
                map.label = 'Resort map';
            }
            this.maps.push(map);
            this.mapsByUrlizedName[map.label.urlize()] = map;
        }

        let sw = this.bounds[0];
        let ne = this.bounds[1];
        this.center = new GPSPoint().with(sw.lat + (ne.lat - sw.lat) / 2,
            sw.lng + (ne.lng - sw.lng) / 2,
            0);

        this.computeStatistics();
    }

    private computeStatistics() {
        for (let lift of this.lifts) {
            if (!this.liftWithMostVertical ||
                this.liftWithMostVertical.verticalRise < lift.verticalRise) {
                this.liftWithMostVertical = lift;
            }
            this.baseElevation = Math.min(this.baseElevation,
                lift.bottomAlt, lift.topAlt);
            var oldTopElevation = this.topElevation;
            this.topElevation = Math.max(this.topElevation,
                lift.bottomAlt, lift.topAlt);
            if (oldTopElevation != this.topElevation) {
                this.liftWithHighestElevation = lift;
            }
        }
        this.verticalDrop = this.topElevation - this.baseElevation;

        for (let skirun of this.skiruns) {
            if (!this.longestSkiRun) {
                this.longestSkiRun = skirun;
            } else if (skirun.length > this.longestSkiRun.length) {
                this.longestSkiRun = skirun;
            }

            if (!this.mostVerticalSkiRun) {
                this.mostVerticalSkiRun = skirun;
            } else if (skirun.vertical() > this.mostVerticalSkiRun.vertical()) {
                this.mostVerticalSkiRun = skirun;
            }
        }
    }

    getSkiRunDifficulties(): SkiRunDifficulty[] {
        // return this.skirunDifficulties.sort((a, b) => a > b ? 1 : -1);
        return this.skirunDifficulties;
    }

    getSkiRunsOfDifficulty(difficulty: SkiRunDifficulty): SkiRun[] {
        return this.skirunsByDifficulty[difficulty];
    }

    // Extends the bounds by potentially adding `p` to `bounds`.
    private extendBounds(p: GPSPoint) {
        this._bounds = GPSPoint.extendBounds(this._bounds as any, p);
    }

    getMapBounds(): LngLat[] {
        return this._bounds.map((p: GPSPoint) => p.mapbox());
    }

    getLiftById(id: number): Lift {
        return this.liftsById[id];
    }

    getLiftByName(name: string): Lift {
        return this.liftsByName[name.toLowerCase()];
    }

    getSkiRunById(id: number): SkiRun {
        return this.skirunsById[id];
    }

    getSkiRunByName(name: string): SkiRun {
        return this.skirunsByName[name.toLowerCase()];
    }

    getRestaurantByName(name: string): Restaurant {
        return this.restaurantsByName[name.toLowerCase()];
    }

    getMapByUrlizedName(name: string): Map {
        return this.mapsByUrlizedName[name];
    }

    updateWithWeatherAndSnow(response: GetResortsResponse2) {
        if (response.snow) {
            this.snowFall24HrsMin = response.snow.snowfallLast24HrMin;
            this.snowFall24HrsMax = response.snow.snowfallLast24HrMax;
            this.baseDepthMin = response.snow.baseDepthMin;
            this.baseDepthMax = response.snow.baseDepthMax;

            [this.snowFall24HrsMin, this.snowFall24HrsMax] =
                this.fixMinMaxValues(this.snowFall24HrsMin, this.snowFall24HrsMax);

            [this.baseDepthMin, this.baseDepthMax] =
                this.fixMinMaxValues(this.baseDepthMin, this.baseDepthMax);

        }

        const miToKm = 1.60934;
        console.log("Extracting weather from", response);
        let icon = this.findFirst(response.observations, 'iconName') || 'unknown.png';
        this.iconCSSname = 'weather_' + icon.replace('.png', '');

        this.temperature = this.findFirst(response.observations, 'temperature');
        let windMph = this.findFirst(response.observations, 'windMph');
        if (windMph) {
            this.windKph = windMph * miToKm;
        }
        this.windDirection = this.windDirectionFromDegrees(
            this.findFirst(response.observations, 'windDegrees'));
        let windGustMph = this.findFirst(response.observations, 'windGustMph');
        if (windGustMph) {
            this.windKph = windGustMph * miToKm;
        }
        this.windChillTemperature = this.findFirst(response.observations,
            'windChillC');
        let visibilityMi = this.findFirst(response.observations,
            'visibilityMi');
        if (visibilityMi) {
            this.visibilityKm = visibilityMi * miToKm;
        }
        console.log("Got resort updated with weather and snow", this);
    }

    private fixMinMaxValues(min?: number, max?: number) {
        if (min === undefined && max !== undefined) {
            min = max;
            max == undefined;
        } else if (min == max) {
            max = undefined;
        }
        return [min, max];
    }

    private findFirst(objects: any[], property: any): any {
        for (let dict of objects) {
            if (dict[property]) {
                return dict[property];
            }
        }
        return undefined;
    }


    private windDirectionFromDegrees(degrees: number): string {
        let range = 22.5 / 2;

        if (degrees < 0) {
            return "";
        } else if (degrees < range) {
            return "N";
        } else if (degrees < 22.5 + range) {
            return "NNE";
        } else if (degrees < 45 + range) {
            return "NE";
        } else if (degrees < 67.5 + range) {
            return "ENE";
        } else if (degrees < 90 + range) {
            return "E";
        } else if (degrees < 112.5 + range) {
            return "ESE";
        } else if (degrees < 135 + range) {
            return "SE";
        } else if (degrees < 157.5 + range) {
            return "SSE";
        } else if (degrees < 180 + range) {
            return "S";
        } else if (degrees < 202.5 + range) {
            return "SSW";
        } else if (degrees < 225 + range) {
            return "SW";
        } else if (degrees < 247.5 + range) {
            return "WSW";
        } else if (degrees < 270 + range) {
            return "W";
        } else if (degrees < 292.5 + range) {
            return "WNW";
        } else if (degrees < 315 + range) {
            return "NW";
        } else if (degrees < 337.5 + range) {
            return "NNW";
        } else if (degrees <= 360) {
            return "N";
        } else {
            return "";
        }
    }

    getSchemaItemType() { return 'http://schema.org/SkiResort'; }

    public renderedForZoomLevel(zoom: number) {
        return zoom >= 13;
    }

    hasLiftNames(): boolean { return true; }
    hasSkiRunNames(): boolean { return true; }
}


export class Lift {
    private _resort: WeakRef<Resort>;
    public get resort(): Resort { return this._resort.deref()!; }

    public liftUid: number;

    public name: string;
    public nameJa?: string;
    public nameCyrillic?: string;

    public points: GPSPoint[] = [];

    public time: number;
    public seats: number;

    public isExpress: boolean;

    public bottomAlt: number;
    public topAlt: number;
    public verticalRise: number;

    // An array of 2 GPSPoint points: the southwest and northeast
    // corners of a rectangle containing all the points of a lift.
    private _bounds: [GPSPoint, GPSPoint] = [
        new GPSPoint().with(90, 180, 0),
        new GPSPoint().with(-90, -180, 0)
    ];
    get bounds(): [GPSPoint, GPSPoint] {
        return this._bounds;
    }

    constructor(proto: Resort_Lift, resort: Resort) {
        this._resort = new WeakRef(resort);
        this.liftUid = proto.liftUid || -1;

        this.name = proto.name;
        this.nameJa = proto.nameJa;
        this.nameCyrillic = proto.nameCyrillic;

        if (proto.points && proto.points.length) {
            for (let pp of proto.points) {
                let point: GPSPoint = new GPSPoint().from(pp);
                this.points.push(point);
            }
        } else if (proto.bottom && proto.top) {
            let bottom = new GPSPoint().from(proto.bottom);
            let top = new GPSPoint().from(proto.top);
            this.points = [bottom, top];
        }

        this.time = proto.time;
        this.seats = proto.seats || -1;

        this.isExpress = proto.isExpress || false;

        this.bottomAlt = this.points[0].alt;
        this.topAlt = this.points[this.points.length - 1].alt;
        this.verticalRise = this.topAlt - this.bottomAlt;

        for (let p of this.points) {
            this._bounds = GPSPoint.extendBounds(this._bounds, p);
        }
    }

    pointsAsTurfPositions() {
        return this.points.map((p: GPSPoint) => [p.lng, p.lat]);
    }
}


export class SkiRun {
    public static AllDifficulties = [
        SkiRunDifficulty.GREEN,
        SkiRunDifficulty.BLUE,
        SkiRunDifficulty.BLACK,
        SkiRunDifficulty.DOUBLE_BLACK,
        SkiRunDifficulty.TERRAIN_PARK,
        SkiRunDifficulty.TRIPLE_BLACK,
        SkiRunDifficulty.RED,
        SkiRunDifficulty.ORANGE,
        SkiRunDifficulty.YELLOW,
    ];

    public static skirunDifficultyToName(difficulty: SkiRunDifficulty) {
        switch (difficulty) {
            case SkiRunDifficulty.GREEN: return 'green';
            case SkiRunDifficulty.BLUE: return 'blue';
            case SkiRunDifficulty.BLACK: return 'black';
            case SkiRunDifficulty.DOUBLE_BLACK: return 'double black';
            case SkiRunDifficulty.TERRAIN_PARK: return 'terrain park';
            case SkiRunDifficulty.TRIPLE_BLACK: return 'triple black';
            case SkiRunDifficulty.RED: return 'red';
            case SkiRunDifficulty.ORANGE: return 'orange';
            case SkiRunDifficulty.YELLOW: return 'yellow';
            default: return 'unspecified';
        }
    }

    private _resort: WeakRef<Resort>;
    public get resort(): Resort { return this._resort.deref()!; }

    public skirunUid: number;

    public name: string;
    public nameJa?: string;
    public nameCyrillic?: string;

    public difficulty: SkiRunDifficulty;
    public get difficultyName() {
        return SkiRun.skirunDifficultyToName(this.difficulty);
    }

    public length: number;

    public points: GPSPoint[] = [];

    // An array of 2 GPSPoint points: the southwest and northeast
    // corners of a rectangle containing all the points of a skirun.
    private _bounds: [GPSPoint, GPSPoint] = [
        new GPSPoint().with(90, 180, 0),
        new GPSPoint().with(-90, -180, 0)
    ];
    get bounds(): [GPSPoint, GPSPoint] {
        return this._bounds;
    }

    constructor(proto: Resort_SkiRun, resort: Resort) {
        this._resort = new WeakRef(resort);
        this.skirunUid = proto.skirunUid;
        this.name = proto.name;
        this.nameJa = proto.nameJa;
        this.nameCyrillic = proto.nameCyrillic;

        this.difficulty = proto.difficulty;
        this.length = proto.length || -1;

        this.points = this.pointsFromProto(proto);

        for (let p of this.points) {
            this._bounds = GPSPoint.extendBounds(this._bounds, p);
        }
    }

    private pointsFromProto(proto: Resort_SkiRun): GPSPoint[] {
        let latitudes = proto.latitudes;
        let longitudes = proto.longitudes;
        let altitudes = proto.altitudes;

        let prev: GPSPoint = new GPSPoint().with(
            latitudes[0] / 1e6,
            longitudes[0] / 1e6,
            altitudes[0] / 1e2);

        let points: GPSPoint[] = [prev];

        for (let i = 1; i < latitudes.length; i++) {
            let lat = latitudes[i] / 1e6;
            let lng = longitudes[i] / 1e6;
            let alt = altitudes[i] / 1e2;
            let p = new GPSPoint().with(
                prev.lat + lat,
                prev.lng + lng,
                prev.alt + alt);
            points.push(p);
            prev = p;
        }
        return points;
    }

    top(): GPSPoint {
        return this.points[0];
    }

    bottom(): GPSPoint {
        return this.points[this.points.length - 1];
    }

    vertical(): number {
        return Math.round(this.points[0].alt -
            this.points[this.points.length - 1].alt);
    }

    pointsAsTurfPositions() {
        return this.points.map((p: GPSPoint) => [p.lng, p.lat]);
    }
}


export class Restaurant {
    private _resort: WeakRef<Resort>;
    public get resort(): Resort { return this._resort.deref()!; }

    public name: string;
    public nameJa?: string;
    public nameCyrillic?: string;

    public location?: GPSPoint;

    // An array of 2 GPSPoint points: the southwest and northeast
    // corners of a rectangle containing all the points of a skirun.
    private _bounds: [GPSPoint, GPSPoint] = [
        new GPSPoint().with(90, 180, 0),
        new GPSPoint().with(-90, -180, 0)
    ];
    get bounds(): [GPSPoint, GPSPoint] {
        return this._bounds;
    }

    constructor(proto: Resort_Restaurant, resort: Resort) {
        this._resort = new WeakRef(resort);

        this.name = proto.name;
        this.nameJa = proto.nameJa;
        this.nameCyrillic = proto.nameCyrillic;

        if (proto.location) {
            this.location = new GPSPoint().from(proto.location);
        }

        this._bounds = GPSPoint.extendBounds(this._bounds, this.location!);
    }
}


export class Map {
    public mapUid: number;

    public label?: string;
    public labelJa?: string;

    public identifier?: string;

    public mappings: GpsPointMapping[];

    // This is a populated by the application when the 2D map is
    // loaded at the request of the user.
    private _imageData?: any;
    public get imageData() { return this._imageData; }
    public set imageData(data: any) {
        this._imageData = data;
        this._imageDataLoaded = true;
    }

    private _imageDataLoaded: boolean = false;
    public get imageDataLoaded() { return this._imageDataLoaded; }

    constructor(proto: Resort_Map) {
        this.mapUid = proto.mapUid;
        this.label = proto.label;
        this.labelJa = proto.labelJa;
        this.identifier = proto.imageIdentifier;
        this.mappings = proto.mappings;
    }

    mappingForGPSPointProto(gpspoint: GPSPointProto): Point2DProto | undefined {
        for (let mapping of this.mappings) {
            let gps = mapping.gps;
            if (gps &&
                gpspoint.latE6 === gps.latE6 &&
                gpspoint.lngE6 === gps.lngE6) {
                return mapping.point;
            }
        }
        return undefined;
    }

    mappingForGPSPoint(gpspoint: GPSPoint): Point2DProto | undefined {
        // console.log("mappingForGPSPoint checking point", gpspoint,
        //     "among mappings", this.mappings);
        // let index = 0;
        for (let mapping of this.mappings) {
            let gps: GPSPointProto | undefined = mapping.gps;
            if (gps && gpspoint.almostEqual(gps)) {
                // console.log("Found mapping", mapping, "after looking at", index);
                return mapping.point;
            }
            // index += 1;
        }
        console.log("Could not find an exact mapping for point", gpspoint);
        return undefined;
    }
}
