// @generated by protobuf-ts 2.9.1 with parameters long_type_string
// @generated from protobuf file "common.proto" (syntax proto2)
// tslint:disable
import { BinaryWriteOptions } from "@protobuf-ts/runtime";
import { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import { BinaryReadOptions } from "@protobuf-ts/runtime";
import { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType as MessageType$ } from "@protobuf-ts/runtime";
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf message GPSPoint
 */
export interface GPSPoint {
    /**
     * The latitude and longitude are expressed in degrees.
     *
     * @generated from protobuf field: sint32 latE6 = 1;
     */
    latE6: number;
    /**
     * @generated from protobuf field: sint32 lngE6 = 2;
     */
    lngE6: number;
    /**
     * Altitude is in meters.
     *
     * @generated from protobuf field: int32 altE2 = 3;
     */
    altE2: number;
}
/**
 * @generated from protobuf message GPSPointExplicitArray
 */
export interface GPSPointExplicitArray {
    /**
     * @generated from protobuf field: repeated GPSPoint points = 1;
     */
    points: GPSPoint[];
}
/**
 * @generated from protobuf message GPSPointMappingArray
 */
export interface GPSPointMappingArray {
    /**
     * @generated from protobuf field: repeated GpsPointMapping mapping = 1;
     */
    mapping: GpsPointMapping[];
}
/**
 * @generated from protobuf message Point2D
 */
export interface Point2D {
    /**
     * The point in 2D coordinate. The origin is considered to be top
     * left.
     *
     * @generated from protobuf field: int32 x = 1;
     */
    x: number;
    /**
     * @generated from protobuf field: int32 y = 2;
     */
    y: number;
}
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf message GpsPointMapping
 */
export interface GpsPointMapping {
    /**
     * @generated from protobuf field: int32 map_uid = 1;
     */
    mapUid: number;
    /**
     * @generated from protobuf field: GPSPoint gps = 2;
     */
    gps?: GPSPoint;
    /**
     * @generated from protobuf field: Point2D point = 3;
     */
    point?: Point2D;
}
/**
 * A package of an array of GPSPoints, similar to how we encode the
 * points in a ski run.
 *
 * @generated from protobuf message GPSPointArray
 */
export interface GPSPointArray {
    /**
     * @generated from protobuf field: repeated sint32 latitudes = 1 [packed = true];
     */
    latitudes: number[];
    /**
     * @generated from protobuf field: repeated sint32 longitudes = 2 [packed = true];
     */
    longitudes: number[];
    /**
     * @generated from protobuf field: repeated sint32 altitudes = 3 [packed = true];
     */
    altitudes: number[];
    /**
     * @generated from protobuf field: repeated sint32 timestamps = 4 [packed = true];
     */
    timestamps: number[];
    /**
     * @generated from protobuf field: repeated sint32 relative_altitudesE2 = 11 [packed = true];
     */
    relativeAltitudesE2: number[];
    /**
     * @generated from protobuf field: repeated sint32 pressuresE2 = 12 [packed = true];
     */
    pressuresE2: number[];
    /**
     * The fields below are used by the device to send additional
     * location information to the server.
     *
     * @generated from protobuf field: repeated sint32 horizontal_accuracyE2 = 7 [packed = true];
     */
    horizontalAccuracyE2: number[];
    /**
     * @generated from protobuf field: repeated sint32 vertical_accuracyE2 = 8 [packed = true];
     */
    verticalAccuracyE2: number[];
    /**
     * @generated from protobuf field: repeated sint32 speedE2 = 9 [packed = true];
     */
    speedE2: number[];
    /**
     * @generated from protobuf field: repeated sint32 directionE2 = 10 [packed = true];
     */
    directionE2: number[];
    /**
     * 0 for not reachable
     * 1 for WiFi
     * 2 for WWAN, e.g. cell phone data
     *
     * @generated from protobuf field: repeated int32 internet_connection = 5 [packed = true];
     */
    internetConnection: number[];
    /**
     * Name of the carrier.
     *
     * @generated from protobuf field: optional string carrier_name = 6;
     */
    carrierName?: string;
}
/**
 * @generated from protobuf message DecimatedLine
 */
export interface DecimatedLine {
    /**
     * @generated from protobuf field: optional int32 uid = 1;
     */
    uid?: number;
    /**
     * @generated from protobuf field: GPSPointArray points = 2;
     */
    points?: GPSPointArray;
}
/**
 * @generated from protobuf message Resort
 */
export interface Resort {
    /**
     * @generated from protobuf field: optional int32 version = 1;
     */
    version?: number;
    /**
     * END-WEB
     * START-WEB
     *
     * @generated from protobuf field: int32 resort_uid = 21;
     */
    resortUid: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: optional string name_ja = 27;
     */
    nameJa?: string;
    /**
     * @generated from protobuf field: optional string name_cyrillic = 28;
     */
    nameCyrillic?: string;
    /**
     * @generated from protobuf field: GPSPoint centroid = 3;
     */
    centroid?: GPSPoint;
    /**
     * The radius is in meters
     *
     * @generated from protobuf field: int32 radiusE2 = 4;
     */
    radiusE2: number;
    /**
     * @generated from protobuf field: repeated string state = 5;
     */
    state: string[];
    /**
     * @generated from protobuf field: repeated string country = 6;
     */
    country: string[];
    /**
     * @generated from protobuf field: string continent = 7;
     */
    continent: string;
    /**
     * The timezone name where this resort is located.
     *
     * @generated from protobuf field: optional string timezone = 14;
     */
    timezone?: string;
    /**
     * @generated from protobuf field: optional string url = 26;
     */
    url?: string;
    /**
     * @generated from protobuf field: optional bytes logo = 17;
     */
    logo?: Uint8Array;
    /**
     * @generated from protobuf field: optional string logo_mime_type = 18;
     */
    logoMimeType?: string;
    /**
     * @generated from protobuf field: repeated Resort.Lift lifts = 8;
     */
    lifts: Resort_Lift[];
    /**
     * @generated from protobuf field: repeated Resort.SkiRun skiruns = 22;
     */
    skiruns: Resort_SkiRun[];
    /**
     * @generated from protobuf field: repeated Resort.Restaurant restaurants = 20;
     */
    restaurants: Resort_Restaurant[];
    /**
     * END-WEB
     * START-WEB
     * The vertices of a polygon describing the boundaries of the ski
     * resort.
     *
     * @generated from protobuf field: optional GPSPointArray boundary = 23;
     */
    boundary?: GPSPointArray;
    /**
     * @generated from protobuf field: repeated Resort.ExtraPoint extra_points = 24;
     */
    extraPoints: Resort_ExtraPoint[];
    /**
     * @generated from protobuf field: repeated Resort.Map maps = 19;
     */
    maps: Resort_Map[];
    /**
     * The resort's hours of operation
     *
     * @generated from protobuf field: repeated Resort.HoursOfOperation hours_of_operation = 15;
     */
    hoursOfOperation: Resort_HoursOfOperation[]; // END-WEB
    // START-WEB
}
/**
 * @generated from protobuf message Resort.Lift
 */
export interface Resort_Lift {
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: optional string name_ja = 12;
     */
    nameJa?: string;
    /**
     * @generated from protobuf field: optional string name_cyrillic = 13;
     */
    nameCyrillic?: string;
    /**
     * @generated from protobuf field: optional int32 lift_uid = 10;
     */
    liftUid?: number;
    /**
     * @generated from protobuf field: GPSPoint bottom = 3;
     */
    bottom?: GPSPoint;
    /**
     * @generated from protobuf field: GPSPoint top = 4;
     */
    top?: GPSPoint;
    /**
     * If the lift is not in a straight line, we encode its points
     * here. The first point in the array is the bottom of the lift,
     * the last one is the top.
     *
     * @generated from protobuf field: repeated GPSPoint points = 11;
     */
    points: GPSPoint[];
    /**
     * @generated from protobuf field: int32 time = 5;
     */
    time: number;
    /**
     * Number of seats in the chair
     *
     * @generated from protobuf field: optional int32 seats = 7;
     */
    seats?: number;
    /**
     * Whether the lift is express
     *
     * @generated from protobuf field: optional bool is_express = 8;
     */
    isExpress?: boolean;
    /**
     * This is true if this lift is the continuation of another
     * one. If the value is true, the current lift is the continuation
     * of the previous one.
     *
     * @generated from protobuf field: optional bool is_continuation = 6;
     */
    isContinuation?: boolean;
    /**
     * List of runs accessible from this lift
     *
     * @generated from protobuf field: repeated int32 skirun_uids = 9;
     */
    skirunUids: number[];
}
/**
 * @generated from protobuf message Resort.SkiRun
 */
export interface Resort_SkiRun {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: optional string name_ja = 9;
     */
    nameJa?: string;
    /**
     * @generated from protobuf field: optional string name_cyrillic = 10;
     */
    nameCyrillic?: string;
    /**
     * @generated from protobuf field: SkiRunDifficulty difficulty = 6;
     */
    difficulty: SkiRunDifficulty;
    /**
     * @generated from protobuf field: int32 skirun_uid = 2;
     */
    skirunUid: number;
    /**
     * @generated from protobuf field: optional float length = 8;
     */
    length?: number;
    /**
     * We package the array of GPSPoints that compose the ski run in
     * three different arrays of ints. This helps save memory on the
     * client side since we're not allocating an array of GPSPoint
     * objects, just three different arrays of integers. In addition
     * this encoding provides the opportunity to compress the array
     * for sending it over the wire by encoding only the difference
     * between the previous point (hence we use sint32 instead of
     * int32).
     *
     * See the protobuf encoding page for more information:
     *
     * https://developers.google.com/protocol-buffers/docs/encoding
     *
     *
     * @generated from protobuf field: repeated sint32 latitudes = 3 [packed = true];
     */
    latitudes: number[];
    /**
     * @generated from protobuf field: repeated sint32 longitudes = 4 [packed = true];
     */
    longitudes: number[];
    /**
     * @generated from protobuf field: repeated sint32 altitudes = 5 [packed = true];
     */
    altitudes: number[];
    /**
     * @generated from protobuf field: optional GPSPointArray bezier_points = 11;
     */
    bezierPoints?: GPSPointArray;
    /**
     * List of lifts that can access this run.
     *
     * @generated from protobuf field: repeated int32 lift_uids = 7;
     */
    liftUids: number[];
}
/**
 * @generated from protobuf message Resort.Restaurant
 */
export interface Resort_Restaurant {
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: optional string name_ja = 4;
     */
    nameJa?: string;
    /**
     * @generated from protobuf field: optional string name_cyrillic = 5;
     */
    nameCyrillic?: string;
    /**
     * @generated from protobuf field: GPSPoint location = 3;
     */
    location?: GPSPoint;
}
/**
 * @generated from protobuf message Resort.Map
 */
export interface Resort_Map {
    /**
     * @generated from protobuf field: int32 map_uid = 9;
     */
    mapUid: number;
    /**
     * END-WEB
     * START-WEB
     *
     * @generated from protobuf field: optional string label = 6;
     */
    label?: string;
    /**
     * @generated from protobuf field: optional string label_ja = 11;
     */
    labelJa?: string;
    /**
     * @generated from protobuf field: optional int64 image_identifier = 10;
     */
    imageIdentifier?: string;
    /**
     * Mappings of GPS locations to points on the map.
     *
     * @generated from protobuf field: repeated GpsPointMapping mappings = 8;
     */
    mappings: GpsPointMapping[];
}
/**
 * @generated from protobuf message Resort.HoursOfOperation
 */
export interface Resort_HoursOfOperation {
    /**
     * @generated from protobuf field: int32 day = 1;
     */
    day: number; // Sun is 0, Saturday is 6
    /**
     * @generated from protobuf field: int32 open_time = 2;
     */
    openTime: number; // seconds from midnight local time
    /**
     * @generated from protobuf field: int32 close_time = 3;
     */
    closeTime: number; // seconds from midnight local time
}
/**
 * @generated from protobuf message Resort.ExtraPoint
 */
export interface Resort_ExtraPoint {
    /**
     * END-WEB
     * START-WEB
     *
     * @generated from protobuf field: GPSPoint point = 2;
     */
    point?: GPSPoint;
}
/**
 * A proto message containing decimated lines for lifts and ski runs
 * at a given zoom level.
 *
 *
 * @generated from protobuf message ResortTrace
 */
export interface ResortTrace {
    /**
     * @generated from protobuf field: optional int32 version = 1;
     */
    version?: number;
    /**
     * @generated from protobuf field: int32 resort_uid = 2;
     */
    resortUid: number;
    /**
     * @generated from protobuf field: int32 zoom = 3;
     */
    zoom: number;
    /**
     * @generated from protobuf field: repeated DecimatedLine lifts = 4;
     */
    lifts: DecimatedLine[];
    /**
     * @generated from protobuf field: repeated ResortTrace.SkiRun skiruns = 5;
     */
    skiruns: ResortTrace_SkiRun[];
    /**
     * @generated from protobuf field: optional GPSPointArray boundary = 6;
     */
    boundary?: GPSPointArray;
}
/**
 * @generated from protobuf message ResortTrace.SkiRun
 */
export interface ResortTrace_SkiRun {
    /**
     * @generated from protobuf field: SkiRunDifficulty difficulty = 1;
     */
    difficulty: SkiRunDifficulty;
    /**
     * @generated from protobuf field: int32 uid = 3;
     */
    uid: number;
    /**
     * @generated from protobuf field: GPSPointArray points = 2;
     */
    points?: GPSPointArray;
}
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf message WeatherStationObservation
 */
export interface WeatherStationObservation {
    /**
     * @generated from protobuf field: string station_id = 1;
     */
    stationId: string;
    /**
     * @generated from protobuf field: GPSPoint position = 13;
     */
    position?: GPSPoint;
    /**
     * @generated from protobuf field: optional string location = 14;
     */
    location?: string;
    /**
     * @generated from protobuf field: float temperature = 2;
     */
    temperature: number; // In Celsius
    /**
     * @generated from protobuf field: int32 observation_time = 3;
     */
    observationTime: number; // Unix timestamp in UTC time
    /**
     * @generated from protobuf field: optional float wind_mph = 4;
     */
    windMph?: number;
    /**
     * @generated from protobuf field: optional float wind_degrees = 5;
     */
    windDegrees?: number;
    /**
     * @generated from protobuf field: optional float wind_gust_mph = 6;
     */
    windGustMph?: number;
    /**
     * @generated from protobuf field: optional float windchill_c = 7;
     */
    windchillC?: number;
    /**
     * @generated from protobuf field: optional string icon_name = 12;
     */
    iconName?: string;
    /**
     * @generated from protobuf field: optional float visibility_mi = 11;
     */
    visibilityMi?: number;
}
/**
 * @generated from protobuf message ResortSnow
 */
export interface ResortSnow {
    /**
     * These numbers are in cm.
     *
     * @generated from protobuf field: optional float snowfall_last24hr_min = 5 [json_name = "snowfallLast24hrMin"];
     */
    snowfallLast24HrMin?: number;
    /**
     * @generated from protobuf field: optional float snowfall_last24hr_max = 6 [json_name = "snowfallLast24hrMax"];
     */
    snowfallLast24HrMax?: number;
    /**
     * @generated from protobuf field: optional float base_depth_min = 7;
     */
    baseDepthMin?: number;
    /**
     * @generated from protobuf field: optional float base_depth_max = 8;
     */
    baseDepthMax?: number; // END-WEB
    // START-WEB
}
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf enum MessageType
 */
export enum MessageType {
    /**
     * @generated synthetic value - protobuf-ts requires all enums to have a 0 value
     */
    UNSPECIFIED$ = 0,
    /**
     * @generated from protobuf enum value: EMPTY = 1;
     */
    EMPTY = 1,
    /**
     * FACEBOOK_AUTH = 2;
     * EMAIL_INVITE = 3;
     *
     * @generated from protobuf enum value: FETCH_ALL_RESORTS = 10;
     */
    FETCH_ALL_RESORTS = 10,
    /**
     * @generated from protobuf enum value: GET_RESORTS = 4;
     */
    GET_RESORTS = 4,
    /**
     * END-WEB
     * START-WEB
     *
     * @generated from protobuf enum value: HEATMAP_REQUEST = 30;
     */
    HEATMAP_REQUEST = 30,
    /**
     * This is an enhanced variant of FETCH_ALL_RESORTS which includes
     * computed statistics for each ski resort.
     *
     * @generated from protobuf enum value: FETCH_ALL_RESORTS2 = 31;
     */
    FETCH_ALL_RESORTS2 = 31,
    /**
     * @generated from protobuf enum value: USER_REQUEST = 32;
     */
    USER_REQUEST = 32,
    /**
     * Labeler requests
     *
     * @generated from protobuf enum value: FETCH_SKIDAY_STATISTICS_FOR_RESORT_REQUEST = 33;
     */
    FETCH_SKIDAY_STATISTICS_FOR_RESORT_REQUEST = 33,
    /**
     * Blog requests
     *
     * @generated from protobuf enum value: SAVE_IMAGE_REQUEST = 34;
     */
    SAVE_IMAGE_REQUEST = 34,
    /**
     * @generated from protobuf enum value: SAVE_BLOG_POST_REQUEST = 35;
     */
    SAVE_BLOG_POST_REQUEST = 35,
    /**
     * @generated from protobuf enum value: FETCH_ADMIN_BLOG_POST_REQUEST = 40;
     */
    FETCH_ADMIN_BLOG_POST_REQUEST = 40,
    /**
     * @generated from protobuf enum value: FETCH_BLOG_POST_REQUEST = 36;
     */
    FETCH_BLOG_POST_REQUEST = 36,
    /**
     * @generated from protobuf enum value: FETCH_LIST_BLOG_POSTS_REQUEST = 37;
     */
    FETCH_LIST_BLOG_POSTS_REQUEST = 37,
    /**
     * @generated from protobuf enum value: DELETE_BLOG_POST_REQUEST = 38;
     */
    DELETE_BLOG_POST_REQUEST = 38,
    /**
     * @generated from protobuf enum value: PUBLISH_BLOG_POST_REQUEST = 39;
     */
    PUBLISH_BLOG_POST_REQUEST = 39,
    /**
     * Markdown pages
     *
     * @generated from protobuf enum value: FETCH_MARKDOWN_PAGE = 41;
     */
    FETCH_MARKDOWN_PAGE = 41,
    /**
     * @generated from protobuf enum value: CHECKOUT_REQUEST = 42;
     */
    CHECKOUT_REQUEST = 42,
    /**
     * @generated from protobuf enum value: PURCHASE_COMPLETED_REQUEST = 43;
     */
    PURCHASE_COMPLETED_REQUEST = 43,
    /**
     * @generated from protobuf enum value: MARK_RESORT_FOR_MANUAL_SNOW = 44;
     */
    MARK_RESORT_FOR_MANUAL_SNOW = 44,
    /**
     * @generated from protobuf enum value: RESORT_SNOW_OBSERVATIONS = 45;
     */
    RESORT_SNOW_OBSERVATIONS = 45,
    /**
     * @generated from protobuf enum value: SAVE_SNOW_OBSERVATION = 46;
     */
    SAVE_SNOW_OBSERVATION = 46,
    /**
     * @generated from protobuf enum value: UPDATE_IMAGE_REQUEST = 47;
     */
    UPDATE_IMAGE_REQUEST = 47,
    /**
     * @generated from protobuf enum value: SKIDAY_LOCATIONS_REQUEST = 48;
     */
    SKIDAY_LOCATIONS_REQUEST = 48,
    /**
     * @generated from protobuf enum value: SKIDAY_LABELER_MARKERS = 49;
     */
    SKIDAY_LABELER_MARKERS = 49,
    /**
     * @generated from protobuf enum value: AUTO_LABELED_MARKERS_REQUEST = 50;
     */
    AUTO_LABELED_MARKERS_REQUEST = 50,
    /**
     * @generated from protobuf enum value: EXISTING_MODELS_REQUEST = 51;
     */
    EXISTING_MODELS_REQUEST = 51,
    /**
     * @generated from protobuf enum value: FETCH_MODEL_REQUEST = 52;
     */
    FETCH_MODEL_REQUEST = 52,
    /**
     * @generated from protobuf enum value: MAKE_MODEL_PRIMARY_REQUEST = 54;
     */
    MAKE_MODEL_PRIMARY_REQUEST = 54,
    /**
     * @generated from protobuf enum value: PUSH_MODEL_TO_PRODUCTION_REQUEST = 55;
     */
    PUSH_MODEL_TO_PRODUCTION_REQUEST = 55,
    /**
     * @generated from protobuf enum value: FETCH_RESORTS = 56;
     */
    FETCH_RESORTS = 56,
    /**
     * @generated from protobuf enum value: FETCH_BOUNDARIES = 57;
     */
    FETCH_BOUNDARIES = 57,
    /**
     * @generated from protobuf enum value: DELETE_USER_REQUEST = 58;
     */
    DELETE_USER_REQUEST = 58
}
/**
 * END-WEB
 * START-WEB
 *
 * @generated from protobuf enum SkiRunDifficulty
 */
export enum SkiRunDifficulty {
    /**
     * @generated synthetic value - protobuf-ts requires all enums to have a 0 value
     */
    UNSPECIFIED$ = 0,
    /**
     * @generated from protobuf enum value: GREEN = 1;
     */
    GREEN = 1,
    /**
     * @generated from protobuf enum value: BLUE = 2;
     */
    BLUE = 2,
    /**
     * @generated from protobuf enum value: BLACK = 3;
     */
    BLACK = 3,
    /**
     * @generated from protobuf enum value: DOUBLE_BLACK = 4;
     */
    DOUBLE_BLACK = 4,
    /**
     * @generated from protobuf enum value: TERRAIN_PARK = 5;
     */
    TERRAIN_PARK = 5,
    /**
     * @generated from protobuf enum value: TRIPLE_BLACK = 6;
     */
    TRIPLE_BLACK = 6,
    /**
     * @generated from protobuf enum value: RED = 7;
     */
    RED = 7,
    /**
     * @generated from protobuf enum value: ORANGE = 8;
     */
    ORANGE = 8,
    /**
     * @generated from protobuf enum value: YELLOW = 9;
     */
    YELLOW = 9
}
/**
 * Type for protobuf message GPSPoint
 */
class GPSPoint$Type extends MessageType$<GPSPoint> {
    constructor() {
        super("GPSPoint", [
            { no: 1, name: "latE6", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 2, name: "lngE6", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "altE2", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GPSPoint>): GPSPoint {
        const message = { latE6: 0, lngE6: 0, altE2: 0 };
        if (value !== undefined)
            reflectionMergePartial<GPSPoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GPSPoint): GPSPoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* sint32 latE6 */ 1:
                    message.latE6 = reader.sint32();
                    break;
                case /* sint32 lngE6 */ 2:
                    message.lngE6 = reader.sint32();
                    break;
                case /* int32 altE2 */ 3:
                    message.altE2 = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GPSPoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* sint32 latE6 = 1; */
        if (message.latE6 !== 0)
            writer.tag(1, WireType.Varint).sint32(message.latE6);
        /* sint32 lngE6 = 2; */
        if (message.lngE6 !== 0)
            writer.tag(2, WireType.Varint).sint32(message.lngE6);
        /* int32 altE2 = 3; */
        if (message.altE2 !== 0)
            writer.tag(3, WireType.Varint).int32(message.altE2);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GPSPoint = new GPSPoint$Type();
/**
 * Type for protobuf message GPSPointExplicitArray
 */
class GPSPointExplicitArray$Type extends MessageType$<GPSPointExplicitArray> {
    constructor() {
        super("GPSPointExplicitArray", [
            { no: 1, name: "points", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => GPSPoint }
        ]);
    }
    create(value?: PartialMessage<GPSPointExplicitArray>): GPSPointExplicitArray {
        const message = { points: [] };
        if (value !== undefined)
            reflectionMergePartial<GPSPointExplicitArray>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GPSPointExplicitArray): GPSPointExplicitArray {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated GPSPoint points */ 1:
                    message.points.push(GPSPoint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GPSPointExplicitArray, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated GPSPoint points = 1; */
        for (let i = 0; i < message.points.length; i++)
            GPSPoint.internalBinaryWrite(message.points[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GPSPointExplicitArray = new GPSPointExplicitArray$Type();
/**
 * Type for protobuf message GPSPointMappingArray
 */
class GPSPointMappingArray$Type extends MessageType$<GPSPointMappingArray> {
    constructor() {
        super("GPSPointMappingArray", [
            { no: 1, name: "mapping", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => GpsPointMapping }
        ]);
    }
    create(value?: PartialMessage<GPSPointMappingArray>): GPSPointMappingArray {
        const message = { mapping: [] };
        if (value !== undefined)
            reflectionMergePartial<GPSPointMappingArray>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GPSPointMappingArray): GPSPointMappingArray {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated GpsPointMapping mapping */ 1:
                    message.mapping.push(GpsPointMapping.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GPSPointMappingArray, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated GpsPointMapping mapping = 1; */
        for (let i = 0; i < message.mapping.length; i++)
            GpsPointMapping.internalBinaryWrite(message.mapping[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GPSPointMappingArray = new GPSPointMappingArray$Type();
/**
 * Type for protobuf message Point2D
 */
class Point2D$Type extends MessageType$<Point2D> {
    constructor() {
        super("Point2D", [
            { no: 1, name: "x", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "y", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Point2D>): Point2D {
        const message = { x: 0, y: 0 };
        if (value !== undefined)
            reflectionMergePartial<Point2D>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Point2D): Point2D {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 x */ 1:
                    message.x = reader.int32();
                    break;
                case /* int32 y */ 2:
                    message.y = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Point2D, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 x = 1; */
        if (message.x !== 0)
            writer.tag(1, WireType.Varint).int32(message.x);
        /* int32 y = 2; */
        if (message.y !== 0)
            writer.tag(2, WireType.Varint).int32(message.y);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Point2D = new Point2D$Type();
/**
 * Type for protobuf message GpsPointMapping
 */
class GpsPointMapping$Type extends MessageType$<GpsPointMapping> {
    constructor() {
        super("GpsPointMapping", [
            { no: 1, name: "map_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "gps", kind: "message", T: () => GPSPoint },
            { no: 3, name: "point", kind: "message", T: () => Point2D }
        ]);
    }
    create(value?: PartialMessage<GpsPointMapping>): GpsPointMapping {
        const message = { mapUid: 0 };
        if (value !== undefined)
            reflectionMergePartial<GpsPointMapping>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GpsPointMapping): GpsPointMapping {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 map_uid */ 1:
                    message.mapUid = reader.int32();
                    break;
                case /* GPSPoint gps */ 2:
                    message.gps = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.gps);
                    break;
                case /* Point2D point */ 3:
                    message.point = Point2D.internalBinaryRead(reader, reader.uint32(), options, message.point);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GpsPointMapping, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 map_uid = 1; */
        if (message.mapUid !== 0)
            writer.tag(1, WireType.Varint).int32(message.mapUid);
        /* GPSPoint gps = 2; */
        if (message.gps)
            GPSPoint.internalBinaryWrite(message.gps, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* Point2D point = 3; */
        if (message.point)
            Point2D.internalBinaryWrite(message.point, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GpsPointMapping = new GpsPointMapping$Type();
/**
 * Type for protobuf message GPSPointArray
 */
class GPSPointArray$Type extends MessageType$<GPSPointArray> {
    constructor() {
        super("GPSPointArray", [
            { no: 1, name: "latitudes", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 2, name: "longitudes", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "altitudes", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 4, name: "timestamps", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 11, name: "relative_altitudesE2", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 12, name: "pressuresE2", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 7, name: "horizontal_accuracyE2", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 8, name: "vertical_accuracyE2", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 9, name: "speedE2", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 10, name: "directionE2", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 5, name: "internet_connection", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "carrier_name", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GPSPointArray>): GPSPointArray {
        const message = { latitudes: [], longitudes: [], altitudes: [], timestamps: [], relativeAltitudesE2: [], pressuresE2: [], horizontalAccuracyE2: [], verticalAccuracyE2: [], speedE2: [], directionE2: [], internetConnection: [] };
        if (value !== undefined)
            reflectionMergePartial<GPSPointArray>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GPSPointArray): GPSPointArray {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated sint32 latitudes = 1 [packed = true];*/ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.latitudes.push(reader.sint32());
                    else
                        message.latitudes.push(reader.sint32());
                    break;
                case /* repeated sint32 longitudes = 2 [packed = true];*/ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.longitudes.push(reader.sint32());
                    else
                        message.longitudes.push(reader.sint32());
                    break;
                case /* repeated sint32 altitudes = 3 [packed = true];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.altitudes.push(reader.sint32());
                    else
                        message.altitudes.push(reader.sint32());
                    break;
                case /* repeated sint32 timestamps = 4 [packed = true];*/ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.timestamps.push(reader.sint32());
                    else
                        message.timestamps.push(reader.sint32());
                    break;
                case /* repeated sint32 relative_altitudesE2 = 11 [packed = true];*/ 11:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.relativeAltitudesE2.push(reader.sint32());
                    else
                        message.relativeAltitudesE2.push(reader.sint32());
                    break;
                case /* repeated sint32 pressuresE2 = 12 [packed = true];*/ 12:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.pressuresE2.push(reader.sint32());
                    else
                        message.pressuresE2.push(reader.sint32());
                    break;
                case /* repeated sint32 horizontal_accuracyE2 = 7 [packed = true];*/ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.horizontalAccuracyE2.push(reader.sint32());
                    else
                        message.horizontalAccuracyE2.push(reader.sint32());
                    break;
                case /* repeated sint32 vertical_accuracyE2 = 8 [packed = true];*/ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.verticalAccuracyE2.push(reader.sint32());
                    else
                        message.verticalAccuracyE2.push(reader.sint32());
                    break;
                case /* repeated sint32 speedE2 = 9 [packed = true];*/ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.speedE2.push(reader.sint32());
                    else
                        message.speedE2.push(reader.sint32());
                    break;
                case /* repeated sint32 directionE2 = 10 [packed = true];*/ 10:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.directionE2.push(reader.sint32());
                    else
                        message.directionE2.push(reader.sint32());
                    break;
                case /* repeated int32 internet_connection = 5 [packed = true];*/ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.internetConnection.push(reader.int32());
                    else
                        message.internetConnection.push(reader.int32());
                    break;
                case /* optional string carrier_name */ 6:
                    message.carrierName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GPSPointArray, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated sint32 latitudes = 1 [packed = true]; */
        if (message.latitudes.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.latitudes.length; i++)
                writer.sint32(message.latitudes[i]);
            writer.join();
        }
        /* repeated sint32 longitudes = 2 [packed = true]; */
        if (message.longitudes.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.longitudes.length; i++)
                writer.sint32(message.longitudes[i]);
            writer.join();
        }
        /* repeated sint32 altitudes = 3 [packed = true]; */
        if (message.altitudes.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.altitudes.length; i++)
                writer.sint32(message.altitudes[i]);
            writer.join();
        }
        /* repeated sint32 timestamps = 4 [packed = true]; */
        if (message.timestamps.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.timestamps.length; i++)
                writer.sint32(message.timestamps[i]);
            writer.join();
        }
        /* repeated sint32 relative_altitudesE2 = 11 [packed = true]; */
        if (message.relativeAltitudesE2.length) {
            writer.tag(11, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.relativeAltitudesE2.length; i++)
                writer.sint32(message.relativeAltitudesE2[i]);
            writer.join();
        }
        /* repeated sint32 pressuresE2 = 12 [packed = true]; */
        if (message.pressuresE2.length) {
            writer.tag(12, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.pressuresE2.length; i++)
                writer.sint32(message.pressuresE2[i]);
            writer.join();
        }
        /* repeated sint32 horizontal_accuracyE2 = 7 [packed = true]; */
        if (message.horizontalAccuracyE2.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.horizontalAccuracyE2.length; i++)
                writer.sint32(message.horizontalAccuracyE2[i]);
            writer.join();
        }
        /* repeated sint32 vertical_accuracyE2 = 8 [packed = true]; */
        if (message.verticalAccuracyE2.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.verticalAccuracyE2.length; i++)
                writer.sint32(message.verticalAccuracyE2[i]);
            writer.join();
        }
        /* repeated sint32 speedE2 = 9 [packed = true]; */
        if (message.speedE2.length) {
            writer.tag(9, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.speedE2.length; i++)
                writer.sint32(message.speedE2[i]);
            writer.join();
        }
        /* repeated sint32 directionE2 = 10 [packed = true]; */
        if (message.directionE2.length) {
            writer.tag(10, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.directionE2.length; i++)
                writer.sint32(message.directionE2[i]);
            writer.join();
        }
        /* repeated int32 internet_connection = 5 [packed = true]; */
        if (message.internetConnection.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.internetConnection.length; i++)
                writer.int32(message.internetConnection[i]);
            writer.join();
        }
        /* optional string carrier_name = 6; */
        if (message.carrierName !== undefined)
            writer.tag(6, WireType.LengthDelimited).string(message.carrierName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GPSPointArray = new GPSPointArray$Type();
/**
 * Type for protobuf message DecimatedLine
 */
class DecimatedLine$Type extends MessageType$<DecimatedLine> {
    constructor() {
        super("DecimatedLine", [
            { no: 1, name: "uid", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "points", kind: "message", T: () => GPSPointArray }
        ]);
    }
    create(value?: PartialMessage<DecimatedLine>): DecimatedLine {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<DecimatedLine>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecimatedLine): DecimatedLine {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int32 uid */ 1:
                    message.uid = reader.int32();
                    break;
                case /* GPSPointArray points */ 2:
                    message.points = GPSPointArray.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecimatedLine, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int32 uid = 1; */
        if (message.uid !== undefined)
            writer.tag(1, WireType.Varint).int32(message.uid);
        /* GPSPointArray points = 2; */
        if (message.points)
            GPSPointArray.internalBinaryWrite(message.points, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const DecimatedLine = new DecimatedLine$Type();
/**
 * Type for protobuf message Resort
 */
class Resort$Type extends MessageType$<Resort> {
    constructor() {
        super("Resort", [
            { no: 1, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "resort_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "name_ja", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "name_cyrillic", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "centroid", kind: "message", T: () => GPSPoint },
            { no: 4, name: "radiusE2", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "state", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "country", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "continent", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "timezone", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "url", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "logo", kind: "scalar", opt: true, T: 12 /*ScalarType.BYTES*/ },
            { no: 18, name: "logo_mime_type", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "lifts", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => Resort_Lift },
            { no: 22, name: "skiruns", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => Resort_SkiRun },
            { no: 20, name: "restaurants", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => Resort_Restaurant },
            { no: 23, name: "boundary", kind: "message", T: () => GPSPointArray },
            { no: 24, name: "extra_points", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => Resort_ExtraPoint },
            { no: 19, name: "maps", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => Resort_Map },
            { no: 15, name: "hours_of_operation", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => Resort_HoursOfOperation }
        ]);
    }
    create(value?: PartialMessage<Resort>): Resort {
        const message = { resortUid: 0, name: "", radiusE2: 0, state: [], country: [], continent: "", lifts: [], skiruns: [], restaurants: [], extraPoints: [], maps: [], hoursOfOperation: [] };
        if (value !== undefined)
            reflectionMergePartial<Resort>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Resort): Resort {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int32 version */ 1:
                    message.version = reader.int32();
                    break;
                case /* int32 resort_uid */ 21:
                    message.resortUid = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* optional string name_ja */ 27:
                    message.nameJa = reader.string();
                    break;
                case /* optional string name_cyrillic */ 28:
                    message.nameCyrillic = reader.string();
                    break;
                case /* GPSPoint centroid */ 3:
                    message.centroid = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.centroid);
                    break;
                case /* int32 radiusE2 */ 4:
                    message.radiusE2 = reader.int32();
                    break;
                case /* repeated string state */ 5:
                    message.state.push(reader.string());
                    break;
                case /* repeated string country */ 6:
                    message.country.push(reader.string());
                    break;
                case /* string continent */ 7:
                    message.continent = reader.string();
                    break;
                case /* optional string timezone */ 14:
                    message.timezone = reader.string();
                    break;
                case /* optional string url */ 26:
                    message.url = reader.string();
                    break;
                case /* optional bytes logo */ 17:
                    message.logo = reader.bytes();
                    break;
                case /* optional string logo_mime_type */ 18:
                    message.logoMimeType = reader.string();
                    break;
                case /* repeated Resort.Lift lifts */ 8:
                    message.lifts.push(Resort_Lift.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Resort.SkiRun skiruns */ 22:
                    message.skiruns.push(Resort_SkiRun.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Resort.Restaurant restaurants */ 20:
                    message.restaurants.push(Resort_Restaurant.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* optional GPSPointArray boundary */ 23:
                    message.boundary = GPSPointArray.internalBinaryRead(reader, reader.uint32(), options, message.boundary);
                    break;
                case /* repeated Resort.ExtraPoint extra_points */ 24:
                    message.extraPoints.push(Resort_ExtraPoint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Resort.Map maps */ 19:
                    message.maps.push(Resort_Map.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated Resort.HoursOfOperation hours_of_operation */ 15:
                    message.hoursOfOperation.push(Resort_HoursOfOperation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Resort, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int32 version = 1; */
        if (message.version !== undefined)
            writer.tag(1, WireType.Varint).int32(message.version);
        /* int32 resort_uid = 21; */
        if (message.resortUid !== 0)
            writer.tag(21, WireType.Varint).int32(message.resortUid);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* optional string name_ja = 27; */
        if (message.nameJa !== undefined)
            writer.tag(27, WireType.LengthDelimited).string(message.nameJa);
        /* optional string name_cyrillic = 28; */
        if (message.nameCyrillic !== undefined)
            writer.tag(28, WireType.LengthDelimited).string(message.nameCyrillic);
        /* GPSPoint centroid = 3; */
        if (message.centroid)
            GPSPoint.internalBinaryWrite(message.centroid, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 radiusE2 = 4; */
        if (message.radiusE2 !== 0)
            writer.tag(4, WireType.Varint).int32(message.radiusE2);
        /* repeated string state = 5; */
        for (let i = 0; i < message.state.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.state[i]);
        /* repeated string country = 6; */
        for (let i = 0; i < message.country.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.country[i]);
        /* string continent = 7; */
        if (message.continent !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.continent);
        /* optional string timezone = 14; */
        if (message.timezone !== undefined)
            writer.tag(14, WireType.LengthDelimited).string(message.timezone);
        /* optional string url = 26; */
        if (message.url !== undefined)
            writer.tag(26, WireType.LengthDelimited).string(message.url);
        /* optional bytes logo = 17; */
        if (message.logo !== undefined)
            writer.tag(17, WireType.LengthDelimited).bytes(message.logo);
        /* optional string logo_mime_type = 18; */
        if (message.logoMimeType !== undefined)
            writer.tag(18, WireType.LengthDelimited).string(message.logoMimeType);
        /* repeated Resort.Lift lifts = 8; */
        for (let i = 0; i < message.lifts.length; i++)
            Resort_Lift.internalBinaryWrite(message.lifts[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated Resort.SkiRun skiruns = 22; */
        for (let i = 0; i < message.skiruns.length; i++)
            Resort_SkiRun.internalBinaryWrite(message.skiruns[i], writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* repeated Resort.Restaurant restaurants = 20; */
        for (let i = 0; i < message.restaurants.length; i++)
            Resort_Restaurant.internalBinaryWrite(message.restaurants[i], writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* optional GPSPointArray boundary = 23; */
        if (message.boundary)
            GPSPointArray.internalBinaryWrite(message.boundary, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* repeated Resort.ExtraPoint extra_points = 24; */
        for (let i = 0; i < message.extraPoints.length; i++)
            Resort_ExtraPoint.internalBinaryWrite(message.extraPoints[i], writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* repeated Resort.Map maps = 19; */
        for (let i = 0; i < message.maps.length; i++)
            Resort_Map.internalBinaryWrite(message.maps[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* repeated Resort.HoursOfOperation hours_of_operation = 15; */
        for (let i = 0; i < message.hoursOfOperation.length; i++)
            Resort_HoursOfOperation.internalBinaryWrite(message.hoursOfOperation[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Resort = new Resort$Type();
/**
 * Type for protobuf message Resort.Lift
 */
class Resort_Lift$Type extends MessageType$<Resort_Lift> {
    constructor() {
        super("Resort.Lift", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "name_ja", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "name_cyrillic", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "lift_uid", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "bottom", kind: "message", T: () => GPSPoint },
            { no: 4, name: "top", kind: "message", T: () => GPSPoint },
            { no: 11, name: "points", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => GPSPoint },
            { no: 5, name: "time", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "seats", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "is_express", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "is_continuation", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "skirun_uids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Resort_Lift>): Resort_Lift {
        const message = { name: "", points: [], time: 0, skirunUids: [] };
        if (value !== undefined)
            reflectionMergePartial<Resort_Lift>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Resort_Lift): Resort_Lift {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* optional string name_ja */ 12:
                    message.nameJa = reader.string();
                    break;
                case /* optional string name_cyrillic */ 13:
                    message.nameCyrillic = reader.string();
                    break;
                case /* optional int32 lift_uid */ 10:
                    message.liftUid = reader.int32();
                    break;
                case /* GPSPoint bottom */ 3:
                    message.bottom = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.bottom);
                    break;
                case /* GPSPoint top */ 4:
                    message.top = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.top);
                    break;
                case /* repeated GPSPoint points */ 11:
                    message.points.push(GPSPoint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 time */ 5:
                    message.time = reader.int32();
                    break;
                case /* optional int32 seats */ 7:
                    message.seats = reader.int32();
                    break;
                case /* optional bool is_express */ 8:
                    message.isExpress = reader.bool();
                    break;
                case /* optional bool is_continuation */ 6:
                    message.isContinuation = reader.bool();
                    break;
                case /* repeated int32 skirun_uids */ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.skirunUids.push(reader.int32());
                    else
                        message.skirunUids.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Resort_Lift, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* optional string name_ja = 12; */
        if (message.nameJa !== undefined)
            writer.tag(12, WireType.LengthDelimited).string(message.nameJa);
        /* optional string name_cyrillic = 13; */
        if (message.nameCyrillic !== undefined)
            writer.tag(13, WireType.LengthDelimited).string(message.nameCyrillic);
        /* optional int32 lift_uid = 10; */
        if (message.liftUid !== undefined)
            writer.tag(10, WireType.Varint).int32(message.liftUid);
        /* GPSPoint bottom = 3; */
        if (message.bottom)
            GPSPoint.internalBinaryWrite(message.bottom, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* GPSPoint top = 4; */
        if (message.top)
            GPSPoint.internalBinaryWrite(message.top, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated GPSPoint points = 11; */
        for (let i = 0; i < message.points.length; i++)
            GPSPoint.internalBinaryWrite(message.points[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* int32 time = 5; */
        if (message.time !== 0)
            writer.tag(5, WireType.Varint).int32(message.time);
        /* optional int32 seats = 7; */
        if (message.seats !== undefined)
            writer.tag(7, WireType.Varint).int32(message.seats);
        /* optional bool is_express = 8; */
        if (message.isExpress !== undefined)
            writer.tag(8, WireType.Varint).bool(message.isExpress);
        /* optional bool is_continuation = 6; */
        if (message.isContinuation !== undefined)
            writer.tag(6, WireType.Varint).bool(message.isContinuation);
        /* repeated int32 skirun_uids = 9; */
        for (let i = 0; i < message.skirunUids.length; i++)
            writer.tag(9, WireType.Varint).int32(message.skirunUids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Resort_Lift = new Resort_Lift$Type();
/**
 * Type for protobuf message Resort.SkiRun
 */
class Resort_SkiRun$Type extends MessageType$<Resort_SkiRun> {
    constructor() {
        super("Resort.SkiRun", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "name_ja", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "name_cyrillic", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "difficulty", kind: "enum", T: () => ["SkiRunDifficulty", SkiRunDifficulty] },
            { no: 2, name: "skirun_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "length", kind: "scalar", opt: true, T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "latitudes", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 4, name: "longitudes", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 5, name: "altitudes", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 17 /*ScalarType.SINT32*/ },
            { no: 11, name: "bezier_points", kind: "message", T: () => GPSPointArray },
            { no: 7, name: "lift_uids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Resort_SkiRun>): Resort_SkiRun {
        const message = { name: "", difficulty: 0, skirunUid: 0, latitudes: [], longitudes: [], altitudes: [], liftUids: [] };
        if (value !== undefined)
            reflectionMergePartial<Resort_SkiRun>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Resort_SkiRun): Resort_SkiRun {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* optional string name_ja */ 9:
                    message.nameJa = reader.string();
                    break;
                case /* optional string name_cyrillic */ 10:
                    message.nameCyrillic = reader.string();
                    break;
                case /* SkiRunDifficulty difficulty */ 6:
                    message.difficulty = reader.int32();
                    break;
                case /* int32 skirun_uid */ 2:
                    message.skirunUid = reader.int32();
                    break;
                case /* optional float length */ 8:
                    message.length = reader.float();
                    break;
                case /* repeated sint32 latitudes = 3 [packed = true];*/ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.latitudes.push(reader.sint32());
                    else
                        message.latitudes.push(reader.sint32());
                    break;
                case /* repeated sint32 longitudes = 4 [packed = true];*/ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.longitudes.push(reader.sint32());
                    else
                        message.longitudes.push(reader.sint32());
                    break;
                case /* repeated sint32 altitudes = 5 [packed = true];*/ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.altitudes.push(reader.sint32());
                    else
                        message.altitudes.push(reader.sint32());
                    break;
                case /* optional GPSPointArray bezier_points */ 11:
                    message.bezierPoints = GPSPointArray.internalBinaryRead(reader, reader.uint32(), options, message.bezierPoints);
                    break;
                case /* repeated int32 lift_uids */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.liftUids.push(reader.int32());
                    else
                        message.liftUids.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Resort_SkiRun, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* optional string name_ja = 9; */
        if (message.nameJa !== undefined)
            writer.tag(9, WireType.LengthDelimited).string(message.nameJa);
        /* optional string name_cyrillic = 10; */
        if (message.nameCyrillic !== undefined)
            writer.tag(10, WireType.LengthDelimited).string(message.nameCyrillic);
        /* SkiRunDifficulty difficulty = 6; */
        if (message.difficulty !== 0)
            writer.tag(6, WireType.Varint).int32(message.difficulty);
        /* int32 skirun_uid = 2; */
        if (message.skirunUid !== 0)
            writer.tag(2, WireType.Varint).int32(message.skirunUid);
        /* optional float length = 8; */
        if (message.length !== undefined)
            writer.tag(8, WireType.Bit32).float(message.length);
        /* repeated sint32 latitudes = 3 [packed = true]; */
        if (message.latitudes.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.latitudes.length; i++)
                writer.sint32(message.latitudes[i]);
            writer.join();
        }
        /* repeated sint32 longitudes = 4 [packed = true]; */
        if (message.longitudes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.longitudes.length; i++)
                writer.sint32(message.longitudes[i]);
            writer.join();
        }
        /* repeated sint32 altitudes = 5 [packed = true]; */
        if (message.altitudes.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.altitudes.length; i++)
                writer.sint32(message.altitudes[i]);
            writer.join();
        }
        /* optional GPSPointArray bezier_points = 11; */
        if (message.bezierPoints)
            GPSPointArray.internalBinaryWrite(message.bezierPoints, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated int32 lift_uids = 7; */
        for (let i = 0; i < message.liftUids.length; i++)
            writer.tag(7, WireType.Varint).int32(message.liftUids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Resort_SkiRun = new Resort_SkiRun$Type();
/**
 * Type for protobuf message Resort.Restaurant
 */
class Resort_Restaurant$Type extends MessageType$<Resort_Restaurant> {
    constructor() {
        super("Resort.Restaurant", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name_ja", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "name_cyrillic", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "location", kind: "message", T: () => GPSPoint }
        ]);
    }
    create(value?: PartialMessage<Resort_Restaurant>): Resort_Restaurant {
        const message = { name: "" };
        if (value !== undefined)
            reflectionMergePartial<Resort_Restaurant>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Resort_Restaurant): Resort_Restaurant {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* optional string name_ja */ 4:
                    message.nameJa = reader.string();
                    break;
                case /* optional string name_cyrillic */ 5:
                    message.nameCyrillic = reader.string();
                    break;
                case /* GPSPoint location */ 3:
                    message.location = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Resort_Restaurant, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* optional string name_ja = 4; */
        if (message.nameJa !== undefined)
            writer.tag(4, WireType.LengthDelimited).string(message.nameJa);
        /* optional string name_cyrillic = 5; */
        if (message.nameCyrillic !== undefined)
            writer.tag(5, WireType.LengthDelimited).string(message.nameCyrillic);
        /* GPSPoint location = 3; */
        if (message.location)
            GPSPoint.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Resort_Restaurant = new Resort_Restaurant$Type();
/**
 * Type for protobuf message Resort.Map
 */
class Resort_Map$Type extends MessageType$<Resort_Map> {
    constructor() {
        super("Resort.Map", [
            { no: 9, name: "map_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "label", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "label_ja", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "image_identifier", kind: "scalar", opt: true, T: 3 /*ScalarType.INT64*/ },
            { no: 8, name: "mappings", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => GpsPointMapping }
        ]);
    }
    create(value?: PartialMessage<Resort_Map>): Resort_Map {
        const message = { mapUid: 0, mappings: [] };
        if (value !== undefined)
            reflectionMergePartial<Resort_Map>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Resort_Map): Resort_Map {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 map_uid */ 9:
                    message.mapUid = reader.int32();
                    break;
                case /* optional string label */ 6:
                    message.label = reader.string();
                    break;
                case /* optional string label_ja */ 11:
                    message.labelJa = reader.string();
                    break;
                case /* optional int64 image_identifier */ 10:
                    message.imageIdentifier = reader.int64().toString();
                    break;
                case /* repeated GpsPointMapping mappings */ 8:
                    message.mappings.push(GpsPointMapping.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Resort_Map, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 map_uid = 9; */
        if (message.mapUid !== 0)
            writer.tag(9, WireType.Varint).int32(message.mapUid);
        /* optional string label = 6; */
        if (message.label !== undefined)
            writer.tag(6, WireType.LengthDelimited).string(message.label);
        /* optional string label_ja = 11; */
        if (message.labelJa !== undefined)
            writer.tag(11, WireType.LengthDelimited).string(message.labelJa);
        /* optional int64 image_identifier = 10; */
        if (message.imageIdentifier !== undefined)
            writer.tag(10, WireType.Varint).int64(message.imageIdentifier);
        /* repeated GpsPointMapping mappings = 8; */
        for (let i = 0; i < message.mappings.length; i++)
            GpsPointMapping.internalBinaryWrite(message.mappings[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Resort_Map = new Resort_Map$Type();
/**
 * Type for protobuf message Resort.HoursOfOperation
 */
class Resort_HoursOfOperation$Type extends MessageType$<Resort_HoursOfOperation> {
    constructor() {
        super("Resort.HoursOfOperation", [
            { no: 1, name: "day", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "open_time", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "close_time", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Resort_HoursOfOperation>): Resort_HoursOfOperation {
        const message = { day: 0, openTime: 0, closeTime: 0 };
        if (value !== undefined)
            reflectionMergePartial<Resort_HoursOfOperation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Resort_HoursOfOperation): Resort_HoursOfOperation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 day */ 1:
                    message.day = reader.int32();
                    break;
                case /* int32 open_time */ 2:
                    message.openTime = reader.int32();
                    break;
                case /* int32 close_time */ 3:
                    message.closeTime = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Resort_HoursOfOperation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 day = 1; */
        if (message.day !== 0)
            writer.tag(1, WireType.Varint).int32(message.day);
        /* int32 open_time = 2; */
        if (message.openTime !== 0)
            writer.tag(2, WireType.Varint).int32(message.openTime);
        /* int32 close_time = 3; */
        if (message.closeTime !== 0)
            writer.tag(3, WireType.Varint).int32(message.closeTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Resort_HoursOfOperation = new Resort_HoursOfOperation$Type();
/**
 * Type for protobuf message Resort.ExtraPoint
 */
class Resort_ExtraPoint$Type extends MessageType$<Resort_ExtraPoint> {
    constructor() {
        super("Resort.ExtraPoint", [
            { no: 2, name: "point", kind: "message", T: () => GPSPoint }
        ]);
    }
    create(value?: PartialMessage<Resort_ExtraPoint>): Resort_ExtraPoint {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<Resort_ExtraPoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Resort_ExtraPoint): Resort_ExtraPoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* GPSPoint point */ 2:
                    message.point = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.point);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Resort_ExtraPoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* GPSPoint point = 2; */
        if (message.point)
            GPSPoint.internalBinaryWrite(message.point, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Resort_ExtraPoint = new Resort_ExtraPoint$Type();
/**
 * Type for protobuf message ResortTrace
 */
class ResortTrace$Type extends MessageType$<ResortTrace> {
    constructor() {
        super("ResortTrace", [
            { no: 1, name: "version", kind: "scalar", opt: true, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "resort_uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "zoom", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "lifts", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => DecimatedLine },
            { no: 5, name: "skiruns", kind: "message", repeat: 2 /*RepeatType.UNPACKED*/, T: () => ResortTrace_SkiRun },
            { no: 6, name: "boundary", kind: "message", T: () => GPSPointArray }
        ]);
    }
    create(value?: PartialMessage<ResortTrace>): ResortTrace {
        const message = { resortUid: 0, zoom: 0, lifts: [], skiruns: [] };
        if (value !== undefined)
            reflectionMergePartial<ResortTrace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResortTrace): ResortTrace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional int32 version */ 1:
                    message.version = reader.int32();
                    break;
                case /* int32 resort_uid */ 2:
                    message.resortUid = reader.int32();
                    break;
                case /* int32 zoom */ 3:
                    message.zoom = reader.int32();
                    break;
                case /* repeated DecimatedLine lifts */ 4:
                    message.lifts.push(DecimatedLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ResortTrace.SkiRun skiruns */ 5:
                    message.skiruns.push(ResortTrace_SkiRun.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* optional GPSPointArray boundary */ 6:
                    message.boundary = GPSPointArray.internalBinaryRead(reader, reader.uint32(), options, message.boundary);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResortTrace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional int32 version = 1; */
        if (message.version !== undefined)
            writer.tag(1, WireType.Varint).int32(message.version);
        /* int32 resort_uid = 2; */
        if (message.resortUid !== 0)
            writer.tag(2, WireType.Varint).int32(message.resortUid);
        /* int32 zoom = 3; */
        if (message.zoom !== 0)
            writer.tag(3, WireType.Varint).int32(message.zoom);
        /* repeated DecimatedLine lifts = 4; */
        for (let i = 0; i < message.lifts.length; i++)
            DecimatedLine.internalBinaryWrite(message.lifts[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated ResortTrace.SkiRun skiruns = 5; */
        for (let i = 0; i < message.skiruns.length; i++)
            ResortTrace_SkiRun.internalBinaryWrite(message.skiruns[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* optional GPSPointArray boundary = 6; */
        if (message.boundary)
            GPSPointArray.internalBinaryWrite(message.boundary, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ResortTrace = new ResortTrace$Type();
/**
 * Type for protobuf message ResortTrace.SkiRun
 */
class ResortTrace_SkiRun$Type extends MessageType$<ResortTrace_SkiRun> {
    constructor() {
        super("ResortTrace.SkiRun", [
            { no: 1, name: "difficulty", kind: "enum", T: () => ["SkiRunDifficulty", SkiRunDifficulty] },
            { no: 3, name: "uid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "points", kind: "message", T: () => GPSPointArray }
        ]);
    }
    create(value?: PartialMessage<ResortTrace_SkiRun>): ResortTrace_SkiRun {
        const message = { difficulty: 0, uid: 0 };
        if (value !== undefined)
            reflectionMergePartial<ResortTrace_SkiRun>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResortTrace_SkiRun): ResortTrace_SkiRun {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SkiRunDifficulty difficulty */ 1:
                    message.difficulty = reader.int32();
                    break;
                case /* int32 uid */ 3:
                    message.uid = reader.int32();
                    break;
                case /* GPSPointArray points */ 2:
                    message.points = GPSPointArray.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResortTrace_SkiRun, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* SkiRunDifficulty difficulty = 1; */
        if (message.difficulty !== 0)
            writer.tag(1, WireType.Varint).int32(message.difficulty);
        /* int32 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int32(message.uid);
        /* GPSPointArray points = 2; */
        if (message.points)
            GPSPointArray.internalBinaryWrite(message.points, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ResortTrace_SkiRun = new ResortTrace_SkiRun$Type();
/**
 * Type for protobuf message WeatherStationObservation
 */
class WeatherStationObservation$Type extends MessageType$<WeatherStationObservation> {
    constructor() {
        super("WeatherStationObservation", [
            { no: 1, name: "station_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "position", kind: "message", T: () => GPSPoint },
            { no: 14, name: "location", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "temperature", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "observation_time", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "wind_mph", kind: "scalar", opt: true, T: 2 /*ScalarType.FLOAT*/ },
            { no: 5, name: "wind_degrees", kind: "scalar", opt: true, T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "wind_gust_mph", kind: "scalar", opt: true, T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "windchill_c", kind: "scalar", opt: true, T: 2 /*ScalarType.FLOAT*/ },
            { no: 12, name: "icon_name", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "visibility_mi", kind: "scalar", opt: true, T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<WeatherStationObservation>): WeatherStationObservation {
        const message = { stationId: "", temperature: 0, observationTime: 0 };
        if (value !== undefined)
            reflectionMergePartial<WeatherStationObservation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WeatherStationObservation): WeatherStationObservation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string station_id */ 1:
                    message.stationId = reader.string();
                    break;
                case /* GPSPoint position */ 13:
                    message.position = GPSPoint.internalBinaryRead(reader, reader.uint32(), options, message.position);
                    break;
                case /* optional string location */ 14:
                    message.location = reader.string();
                    break;
                case /* float temperature */ 2:
                    message.temperature = reader.float();
                    break;
                case /* int32 observation_time */ 3:
                    message.observationTime = reader.int32();
                    break;
                case /* optional float wind_mph */ 4:
                    message.windMph = reader.float();
                    break;
                case /* optional float wind_degrees */ 5:
                    message.windDegrees = reader.float();
                    break;
                case /* optional float wind_gust_mph */ 6:
                    message.windGustMph = reader.float();
                    break;
                case /* optional float windchill_c */ 7:
                    message.windchillC = reader.float();
                    break;
                case /* optional string icon_name */ 12:
                    message.iconName = reader.string();
                    break;
                case /* optional float visibility_mi */ 11:
                    message.visibilityMi = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WeatherStationObservation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string station_id = 1; */
        if (message.stationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stationId);
        /* GPSPoint position = 13; */
        if (message.position)
            GPSPoint.internalBinaryWrite(message.position, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* optional string location = 14; */
        if (message.location !== undefined)
            writer.tag(14, WireType.LengthDelimited).string(message.location);
        /* float temperature = 2; */
        if (message.temperature !== 0)
            writer.tag(2, WireType.Bit32).float(message.temperature);
        /* int32 observation_time = 3; */
        if (message.observationTime !== 0)
            writer.tag(3, WireType.Varint).int32(message.observationTime);
        /* optional float wind_mph = 4; */
        if (message.windMph !== undefined)
            writer.tag(4, WireType.Bit32).float(message.windMph);
        /* optional float wind_degrees = 5; */
        if (message.windDegrees !== undefined)
            writer.tag(5, WireType.Bit32).float(message.windDegrees);
        /* optional float wind_gust_mph = 6; */
        if (message.windGustMph !== undefined)
            writer.tag(6, WireType.Bit32).float(message.windGustMph);
        /* optional float windchill_c = 7; */
        if (message.windchillC !== undefined)
            writer.tag(7, WireType.Bit32).float(message.windchillC);
        /* optional string icon_name = 12; */
        if (message.iconName !== undefined)
            writer.tag(12, WireType.LengthDelimited).string(message.iconName);
        /* optional float visibility_mi = 11; */
        if (message.visibilityMi !== undefined)
            writer.tag(11, WireType.Bit32).float(message.visibilityMi);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const WeatherStationObservation = new WeatherStationObservation$Type();
/**
 * Type for protobuf message ResortSnow
 */
class ResortSnow$Type extends MessageType$<ResortSnow> {
    constructor() {
        super("ResortSnow", [
            { no: 5, name: "snowfall_last24hr_min", kind: "scalar", jsonName: "snowfallLast24hrMin", opt: true, T: 2 /*ScalarType.FLOAT*/ },
            { no: 6, name: "snowfall_last24hr_max", kind: "scalar", jsonName: "snowfallLast24hrMax", opt: true, T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "base_depth_min", kind: "scalar", opt: true, T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "base_depth_max", kind: "scalar", opt: true, T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value?: PartialMessage<ResortSnow>): ResortSnow {
        const message = {};
        if (value !== undefined)
            reflectionMergePartial<ResortSnow>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResortSnow): ResortSnow {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* optional float snowfall_last24hr_min = 5 [json_name = "snowfallLast24hrMin"];*/ 5:
                    message.snowfallLast24HrMin = reader.float();
                    break;
                case /* optional float snowfall_last24hr_max = 6 [json_name = "snowfallLast24hrMax"];*/ 6:
                    message.snowfallLast24HrMax = reader.float();
                    break;
                case /* optional float base_depth_min */ 7:
                    message.baseDepthMin = reader.float();
                    break;
                case /* optional float base_depth_max */ 8:
                    message.baseDepthMax = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResortSnow, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* optional float snowfall_last24hr_min = 5 [json_name = "snowfallLast24hrMin"]; */
        if (message.snowfallLast24HrMin !== undefined)
            writer.tag(5, WireType.Bit32).float(message.snowfallLast24HrMin);
        /* optional float snowfall_last24hr_max = 6 [json_name = "snowfallLast24hrMax"]; */
        if (message.snowfallLast24HrMax !== undefined)
            writer.tag(6, WireType.Bit32).float(message.snowfallLast24HrMax);
        /* optional float base_depth_min = 7; */
        if (message.baseDepthMin !== undefined)
            writer.tag(7, WireType.Bit32).float(message.baseDepthMin);
        /* optional float base_depth_max = 8; */
        if (message.baseDepthMax !== undefined)
            writer.tag(8, WireType.Bit32).float(message.baseDepthMax);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ResortSnow = new ResortSnow$Type();
